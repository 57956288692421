import React from 'react';



class PhoneLink extends React.Component {
	constructor(props, context) {
	  super(props, context);
  	 
	  this.state = {
         data:props.data
        };
	}
   
 	
	render() {

    
        return (
            <a href={'tel:'+this.state.data}>
                {this.state.data}
            </a>
        );
		
	}
  }

  export default PhoneLink;
