import React from 'react';


class SearchAtoZ extends React.Component {
	constructor(props) {
		  super(props);  	 
		  this.state = {
			selectedOption:""
		  };
		  this.handleChange = this.handleChange.bind(this);
		}
	
	  handleChange(event) {
		  window.location.href = event.target.value;
		  }

   
 	
	render() {
		const letters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
		const listOptions = letters.map((letter) =>
		  <option key={letter} value={"/phonebook/browse/"+letter}>{letter}</option>
		);
        return (

        	<nav aria-label="Browse A to Z" className="BrowseSearch">
			  <label htmlFor="azSearch" className="sr-only">A to Z Search</label>
      		  <select id="azSearch" name="azSearch" className="d-block form-control" value={this.state.selectedOption} onChange={this.handleChange}>
	  			<option value="">Browse the A to Z Index</option>
			     {listOptions}
			  </select>
      		</nav>
      		
      		
        );
  }
}

export default SearchAtoZ;