import React from 'react';
import { config } from '../../config';
import Error from './Error.js';
import axios from 'axios';
import qs from 'qs';

import { ReCaptcha } from 'react-recaptcha-v3'



class ContactForm extends React.Component {
	constructor(props, context) {
	  super(props, context);
  	 
	  this.state = {
			data:props.data,
			contactName : props.contactName || 'Me',
			contactId : props.contactId || null,
			senderName: '',
			senderEmail: '',
			emailSubject: '',
			emailMessage: '',
			error: null,
			status:null
        };
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	verifyCallback = function (response) {
		console.log('V3')
		console.log(response);
		window.recaptcha_resp = response;
	};


	handleSubmit(event) {
		this.recaptcha.execute();
		var params = {...this.state};
		params.recaptcha_resp = window.recaptcha_resp;
		params.version =  config.version;

		const options = {
			method: 'POST',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			data:  qs.stringify(params) ,
			url: config.wsServer+'/apps/ws/phonebook/public/contact/send'
		};
		axios(options).then( resp => {
					this.setState({status: resp.data});
				}).catch(console.error);;
	 

	    event.preventDefault();
	}


	render() {
		if ( this.state.status !== null  && this.state.status.title  === 'ok' ) {
			return 	<div className="alert alert-success p-3">{this.state.status.results}</div>
		}

        return (
			<div className="bg-beige rounded p-3">

				{this.state.status !== null  && this.state.status !== 'ok'  ?   
					<div class="alert alert-warning"><strong>Your message could not be sent</strong>
						 
						{ typeof this.state.status.results === 'object'?
							 <ul>{this.state.status.results.map( (item,index) =>  
								 <li  key={index}>{item}</li>       )           
							 }
							</ul>	 
	                          : <div>{this.state.status.results}</div>}         
						 
					</div>  : ''}

				{ this.state.error !== null ?   <Error message={this.state.error} />  : ''}


	        	<h5>Contact {this.state.contactName}</h5>
	        	<form onSubmit={this.handleSubmit}>
		        	<div className="form-group row">
			          <label htmlFor="senderName" className="col-sm-4 text-sm-right col-form-label">Your name</label>
			          <div className="col-sm-8">
			          	<input type="text" className="form-control" id="senderName" value={this.state.senderName} onChange={e => this.setState({ senderName: e.target.value })} placeholder="Enter your name"/>
			          </div>
			        </div>
			        <div className="form-group row">
			          <label htmlFor="senderEmail" className="col-sm-4 text-sm-right col-form-label">Your email address</label>
			          <div className="col-sm-8">
			          	<input type="email" className="form-control" id="senderEmail" value={this.state.senderEmail} onChange={e => this.setState({ senderEmail: e.target.value })}  placeholder="Enter your email address"/>
			          </div>
			        </div>
			        <div className="form-group row">
			          <label htmlFor="emailSubject" className="col-sm-4 text-sm-right col-form-label">Subject</label>
			          <div className="col-sm-8">
			          	<input type="text" className="form-control" id="emailSubject" value={this.state.emailSubject} onChange={e => this.setState({ emailSubject: e.target.value })} placeholder="Subject of your message"/>
			          </div>
			        </div>
			        <div className="form-group row">
			          <label htmlFor="emailMessage" className="col-sm-4 text-sm-right col-form-label">Message</label>
			          <div className="col-sm-8">
			          	<textarea className="form-control" id="emailMessage" value={this.state.emailMessage} onChange={e => this.setState({ emailMessage: e.target.value })} rows="3"></textarea>
			          </div>
			        </div>

			        <div className="form-group row">
						<div className="col-sm-4">&nbsp;</div>
						<div className="col-sm-8">
						<ReCaptcha
								ref={ref => this.recaptcha = ref}
								sitekey={config.recaptcha.sitekey}
								action='submit'
								
								verifyCallback={this.verifyCallback}
							/>

						</div>
				    </div>
		        	<div className="text-center">
			        	<button  value="submitContact" name="submitContact" className="btn btn-secondary">Submit</button>
			        </div>
			     </form>
	        </div>
        );
		
	}
  }



  export default ContactForm;
  
  
