import React from 'react';
//import axios from 'axios';
import Error from './../utils/Error.js';
import {Link } from 'react-router-dom'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const timestamp = new Date().getTime();
const expire = timestamp + (60 * 60 * 24 * 1000 * 365);
const expireDate = new Date(expire);

class Login extends React.Component {
	constructor(props, context) {
	  super(props, context);

      this.state = {
            loginResp: props.loginResp,
//            loginStatus: props.loginStatus,
  //          loginUser: props.loginUser,

            loginURL: null,
            logoutURL: null,
            error: null,
            path: props.path
           // returnPage: props.match.params.return || null,
           // returnID: props.match.params.id || null
      };
     // this.login = this.login.bind(this);
     // this.logout = this.logout.bind(this);

	}

    componentDidMount () {
        if (this.props.forceLogin && !(this.props.loginResp.stat === '1' ||  this.props.loginResp.stat === 1 )) {
            window.location.href = this.state.loginResp.loginURL;
        }
        
    }
    render() {              
       
    	
       if (  this.state.error != null) {
            return (  <Error message={this.state.error} />  );
        } 
    
        if ( this.props.loginResp.stat === '1' ||  this.props.loginResp.stat === 1 ) {
            if (this.props.forceLogin ){
                return <div class="alert alert-success">Login successful</div>
            } else {
                return (    
                	<ul className="d-none" id="loginLink">
                        <li className="nav-item">
                        <Link to={window.location.href} onClick={this.props.logout} className="btn btn-warning scu-logout-link" data-toggle="popover"><i className="fa fa-lock mr-1"><span className="sr-only">Lock</span></i> Logout of SCU-only View</Link>
                        </li>
                    </ul>
                )                
            }
        } else {                          
            return  (
                    <ul className="d-none" id="loginLink">
                        <li className="nav-item">
                        <Link to={window.location.href} onClick={this.props.login} className="btn btn-warning scu-login-link" data-toggle="popover"><i className="fa fa-lock mr-1"><span className="sr-only">Lock</span></i> Switch to SCU-only View</Link>
                        </li>
                    </ul>  
                        
                )
        } 
          
        
    }
  }
  
  document.onreadystatechange = () => {
	  if (document.readyState === 'complete') {
		  var loginLink = document.getElementById("loginLink");
      loginLink.setAttribute("class", "navbar-nav d-block");
      loginLink.setAttribute("style", "position:absolute;top:40px;right:15px;z-index:1000");
		  document.getElementById("scuLoginButton").appendChild(loginLink); 
		  var popoverStatus = cookies.get('loginPopover');
		  if (popoverStatus !== 'read') {
		  window.$('[data-toggle="popover"]').popover({
				  placement:'top',
				  html : true,
				  title : '<strong>Login for full details <a href="#" id="close" class="float-right" data-dismiss="alert">close</a></strong>',
				  content:"<p>Get direct contact email, phone, and location information by logging in with your SCU ID.</p>"
				}).popover('show');
			  window.$(document).on("click", ".popover #close" , function(){
			        window.$(this).parents(".popover").popover('hide');
			        cookies.set('loginPopover', 'read', {path: '/', expires: expireDate});
			    });
  			}
	  }
	};
  export default Login;