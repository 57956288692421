import React, { Component } from 'react';
import axios from 'axios';
import {config} from './../../config.js';
import Loading from './../utils/Loading.js';
import GoogleMap from '../utils/mapComponents/GoogleMap.js';
import FloorMap from '../utils/mapComponents/FloorMap.js';
import SuggestChanges from '../utils/SuggestChanges.js';
import MapWelcome from '../utils/mapComponents/MapWelcome.js';
import LeftCol from './location/LeftCol.js';
import {Link } from 'react-router-dom'

  
class Location extends Component {
	constructor(props) {
		super(props);      
		

		this.state = { id: (props.match && props.match.params.id ? props.match.params.id :null) ,
			flr: (props.match && props.match.params.flr ? props.match.params.flr : null),
			floc: (props.match && props.match.params.floc? props.match.params.floc: null),
			multipleMatches: null,
								obDetails:{} } ;       
		this.chooseFloor = this.chooseFloor.bind(this);
		this.chooseRoom = this.chooseRoom.bind(this);

	}

	componentDidMount() {
		 
		axios.get(config.wsServer+'/apps/ws/phonebook/public/location/'+this.state.id+
			( this.state.flr? '/'+ this.state.flr + ( this.state.floc? '/'+ this.state.floc:'')  :''))
		.then( resp => {
				if ( resp.data.title ==='ok') {
					this.setState({obDetails: resp.data.results.loc, multipleMatches:resp.data.results.locs });               
				}
		})
		.catch(console.error);

		this.props.updateRT('location');
	
	}

	chooseFloor(event){
		//this.setState({flr:  event.target.value});
		window.location = this.getLocationLink() +'/'+event.target.value.replace(' ' ,'-');
	}
	chooseRoom(event){
		window.location = this.getLocationLink() +'/'+this.state.flr +'/' +event.target.value.replace(' ' ,'-');
	}

	getLocationLink() {
		//var safeName = encodeURI(this.state.obDetails.commonName.trim().replace(/ /g,'-').replace(/,/g,''));
		return  config.basePath + '/location/'+this.state.id;
	}


	render() {              

		if ( typeof this.state.id == 'undefined' ) {
			return (
				<div className="Location container py-3 container-map align-items-center justify-content-center justify-content-lg-between">
					<div class="row">
						<div className="col-md-4 mapLeft"> 
							 <MapWelcome />
						</div>
						<div className="col-md-8 mapRight"> 
						 
							 <GoogleMap lat={config.map.scuLat} lng={config.map.scuLng} />
							 
							 
							 
						</div>
					</div>
				</div>          
			);

		} else	if ( typeof this.state.multipleMatches != 'undefined' && this.state.multipleMatches !== null   && this.state.multipleMatches.length > 0 ) {
			return (
				<div className="Location container py-3 container-map align-items-center justify-content-center justify-content-lg-between">
					<h5>Multiple Locations Found</h5>
					<ul>
					 	{this.state.multipleMatches.map((loc, index) => {
									return (
									<li className="list-group-item" key={index}>
										<a href={"/l/"+loc.loc_id}>{loc.name}</a>								 
									</li>
									)
								})}
					</ul>			
				</div>          
			);

		} else	if ( this.state.obDetails === 'Not Found' ||  this.state.obDetails === null  ||  !this.state.obDetails  ) {
			return (
				<div className="Location alert alert-warning container py-3 align-items-center justify-content-center justify-content-lg-between">
					Error: The location was not found.
				</div>          
			);
				
		} else if ( typeof this.state.obDetails.loc_id == 'undefined' ) {
				return (
				<Loading />          
			);
		} else {
			var loc = this.state.obDetails;
			document.title = (loc.commonName ? loc.commonName: loc.name) + ' - ' + config.pageTitleBase;            

			 
			if ( this.state.flr && loc.all_floors.length > 0 ) {
				var floorIndex = 0;

				if ( this.state.flr !== 'floors' && loc.floor) {
					for ( var k=0; k< loc.all_floors.length; k ++ ) {
						if ( loc.all_floors[k].fid === loc.floor.fid ) {
							floorIndex = k;
						}
					}
				}

				if ( ! loc.isAuthenticated ) {
					return <div className="Location container py-3 container-map align-items-center justify-content-center justify-content-lg-between">
							<h3>{loc.name} </h3>
							<div className="row">
								<div className="grid-item p-2 grid-01 col-md-7">
									<div className="alert alert-success m-4">
										<h4>Good News! This building's floor maps are available to SCU users</h4>
										<h5 className="my-3"><Link to={window.location.href} onClick={this.props.login} className="btn btn-warning"><i className="fa fa-lock mr-1"><span className="sr-only">Lock</span></i> Log in to view Floor Maps</Link></h5>
										
									</div>
									
								</div>
								<div className="mt-5 p-4 col-md-5"> 	
									
									<div className="text-center">
										<a href={this.getLocationLink()} className="btn btn-info m-3">More about: {loc.commonName}</a>
									</div>
									
									<GoogleMap markerlat={loc.gps_latitude} markerlng={loc.gps_longitude}  mapHeight={300}/>
								</div>														
							</div>					
						</div>

				} else {

					return <div className="Location container py-3 container-map align-items-center justify-content-center justify-content-lg-between">
							<h3>{loc.name} </h3>
							<div className="row">
								<div className="grid-item p-2 grid-01 col-md-7  text-center">
									<div className="form-inline alert alert-success"> 
									Floor: &nbsp;<select className="form-control"  onChange={this.chooseFloor} defaultValue={loc.floor.flrHR || loc.all_floors[0].flrHR} >							
											{loc.all_floors.map(  (flr) =>  
												<option value={flr.flrHR} key={flr.fid}>{flr.flrHR}</option>
											)}
										</select>
										&nbsp;Room: &nbsp;<select className="form-control" onChange={this.chooseRoom} defaultValue={loc.floor && loc.floor.room && loc.floor.room.floc_id ? loc.floor.room.roomnum : null}>							
												<option value="">-- Select a Room --</option>
											{ loc.all_floors[floorIndex].floc_ids.map(  (floc) =>  
												<option value={floc.roomnum} key={floc.floc_id}>{floc.roomnum} {floc.roomname}</option>
											)}
										</select>
									</div>

									<FloorMap data={loc.floor || loc.all_floors[0]} />
								</div>
								<div className="mt-5 p-4 col-md-5"> 	
									
									<div className="text-center">
										<a href={this.getLocationLink()} className="btn btn-info m-3">More about: {loc.commonName}</a>
									</div>
									
									<GoogleMap markerlat={loc.gps_latitude} markerlng={loc.gps_longitude}  mapHeight={300}/>
								</div>														
							</div>					
						</div>
				
				}

			} else {
				return (
					<div className="Location container py-3 container-map align-items-center justify-content-center justify-content-lg-between">
						<div className="row"> 
							<div className="col-lg-4 col-xs-12">
								<LeftCol location={loc} />
							</div>
							<div className="col-lg-8 col-xs-12">
								<GoogleMap markerlat={loc.gps_latitude} markerlng={loc.gps_longitude} />
							</div>			
						</div>	
					
						<SuggestChanges auth={loc.isAuthenticated} type="loc" id={loc.loc_id} hr={"location: "+loc.name} /> 
					</div> 
					
				);
			}
		}	 
	}

	getOrdinal(n) {
		if ( isNaN(n ))
			return n;
		var s=["th","st","nd","rd"],
		v=n%100;
		return n+(s[(v-20)%10]||s[v]||s[0]);
	 }
}

  
export default Location;