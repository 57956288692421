
import React from 'react';
import './../../style/IfLogin.css';
import {  Link }  from "react-router-dom";
import LocationLink from './LocationLink';
import PhoneLink from './PhoneLink';

class IfLogin extends React.Component {
	constructor(props, context) {
	  super(props, context);
  
	 
	  this.state = {		
			value: this.props.value || '',
			link: this.props.link || '',
			title: this.props.title || '',
			locationLinkData: this.props.locLink || {},
			phoneLink: props.posphone || null,
			linktext: props.linktext || null,
			className: props.className || null
	  };
	}
  
	
	render() {

		if (this.state.value.trim() === '') {
			return (
				<div className="IfLogin">  
					<span className="mut3ed"> 
						<small>n/a</small>
					</span>
				</div>  
			) 

		} else if (this.state.value === 'login_required') {
	   
			return (
				   
					<span className="loginToView "> 
						 <Link to='/login' className="onHoverOnly" onClick={this.props.onLogin}>Login to view {this.state.title}</Link>		
						 {this.state.title === 'email'? <span className="blurred" aria-hidden="true"><span className="d-none d-lg-inline">example@domain.com</span><span className="d-inline d-lg-none">Login to view email</span></span>:<></>}				 
						 {this.state.title === 'phone'? <span className="blurred" aria-hidden="true"><span className="d-none d-lg-inline">(408)000-000</span><span className="d-inline d-lg-none">Login to view phone</span></span>:<></>}				 
						 {this.state.title === 'location'? <span className="blurred" aria-hidden="true"><span className="d-none d-lg-inline">Location Name</span><span className="d-inline d-lg-none">Login to view location</span></span>:<></>}				 
						 {this.state.title === ''? <span className="blurred" aria-hidden="true">LoginToView</span>:<></>}				 
						</span>
					 
			 
			) 
		} else if (this.state.locationLinkData && this.state.locationLinkData.loc_id) {
			return <LocationLink data={this.state.locationLinkData} linktext={this.state.linktext} className={this.state.className} />
		} else if (this.state.title === 'phone') {
			return <PhoneLink data={this.state.value} />
		} else {
			return (
				<div className="IfLogin">
					{this.state.link?
						<Link to={this.state.link}></Link>
						:this.state.value}
				</div>
			);
		}
	}
  }

  export default IfLogin;


