import React, { Component } from 'react';
import { config } from './../../config.js';
import Position from './../utils/Position.js';
import Error from './../utils/Error.js';
import IfLogin from './../utils/IfLogin.js';
import Loading from './../utils/Loading.js';
import SocialIcon from './../utils/SocialIcon.js';
import './../../style/Person.css';
import Icon from './../utils/Icon.js';
import ContactForm from './../utils/ContactForm.js';
//import SuggestChanges from '../utils/SuggestChanges.js';
import placeholder from './../../static/placeholder.png';
import NameCoachBtn from '../utils/NameCoachBtn.js';

class Person extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: null,
			//prefetchedData: props.prefetchedData||null,
			error: null,
			obDetails: props.prefetchedData || {}
		};
		if (props && props.match && props.match.params && props.match.params.id) {
			this.state.id = props.match.params.id;
		}
	}

	componentDidMount() {
		//if this was a single search result or SEO, the results will have been pre-fetched. Only look them up using jsonp if this is not the case
		if (!this.state.obDetails.person_id) {
			const jsonp = require('jsonp');

			jsonp(config.wsServer + '/apps/ws/phonebook/public/person/' + this.state.id, null, (err, data) => {
				if (err) {
					this.setState({ error: err.message });
				} else {
					if (data.title === 'ok') {
						this.setState({ obDetails: data.results.person, error: null });
					} else {
						this.setState({ error: data.results });
					}

				}
			});
			this.props.updateRT('person');
		}
	}



	render() {
		if (this.state.error != null) {
			return (<Error message={this.state.error} />);
		} else if (typeof this.state.id == 'undefined') {
			return (
				<div className="Person">
					Error: no person specified
				</div>
			);
		} else if (typeof this.state.obDetails.person_id == 'undefined') {
			return (<Loading />);
		} else {
			var person = this.state.obDetails;
			document.title = person.last_name + ', ' + person.first_name + ' - ' + config.pageTitleBase;


			//<SuggestChanges auth={person.isAuthenticated} type="person" id={person.person_id} hr={"person: "+ person.first_name+' '+person.last_name} />
			return (<div className="container py-3 Person">

				<div className="row">
					<div className="col-sm-3 pt-3 px-sm-3 d-none d-md-block text-center">
						<img src={person.picture_filename ? person.picture_filename : placeholder} alt={person.first_name + ' ' + person.last_name} className="w-75 border border-light rounded-circle" />
						<div className="mt-3 small">
							{person.website.length > 0 ?
								<div><a href={person.website}>Personal website <i className="fa fa-external-link-alt"><span className="sr-only">External Link</span></i></a></div>
								: ''}
							<div className="social-icons">
								<SocialIcon type="facebook" icon="facebook-squared" link={person.scl_facebook} />
								<SocialIcon type="instagram" link={person.scl_instagram} />
								<SocialIcon type="linkedin" link={person.scl_linkedin} />
								<SocialIcon type="twitter" link={person.scl_twitter} />
								<SocialIcon type="youtube" link={person.scl_youtube} />
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-9 px-sm-0 pt-3 col-sm-3">
						<h3 className="h1 text-sans-light d-none d-md-block">
							{person.pertitle} {person.first_name} {person.middle}  {person.last_name} {person.suffix} 
							{person.pronouns && <small class="xsmall text-muted">&nbsp;({person.pronouns}) &nbsp;</small> }
							{person.name_coach_url && <NameCoachBtn nameCoachURL={person.name_coach_url} />}
						</h3>
						<h4 className="text-sans-light d-none d-md-block"><Icon name="envelope" sr="Email" /> <IfLogin value={person.email} title="email" onLogin={this.props.login} /></h4>
						<div className="d-xs-block d-md-none">
							<div className="d-xs-block d-md-none mb-3 clearfix">
								<img src={person.picture_filename} alt={person.first_name + ' ' + person.last_name} className="w-25 border border-light rounded-circle float-left" />
								<div className="w-75 pl-3 pt-2 float-left">
									<div className="mb-2">
										<h3 className="text-sans-light">{person.pertitle} {person.first_name} {person.middle}  {person.last_name} {person.suffix} 
											{person.pronouns && <small class="xsmall text-muted">({person.pronouns})</small> }
										</h3>
										<Icon name="envelope" sr="Email" />
										<IfLogin value={person.email} title="email" onLogin={this.props.login} />
										{person.website.length > 0 ?
											<div><a href={person.website}>Personal website <i className="fa fa-external-link-alt"><span className="sr-only">External Link</span></i></a></div>
											: ''}
									</div>
								</div>

								<div className="social-icons">
									<SocialIcon type="facebook" icon="facebook-squared" link={person.scl_facebook} />
									<SocialIcon type="instagram" link={person.scl_instagram} />
									<SocialIcon type="linkedin" link={person.scl_linkedin} />
									<SocialIcon type="twitter" link={person.scl_twitter} />
									<SocialIcon type="youtube" link={person.scl_youtube} />

								</div>
							</div>
						</div>
						<div dangerouslySetInnerHTML={createMarkup(person.perdescription)}></div>


						<div className="container p-0">
							<div className="card-deck mt-3">
								{person.positions.map((pos, index) => {
									return <Position key={index} pos={pos} login={this.props.login} layout="person" addSpacer={index > 0} />
								})}
							</div>
						</div>
						{person.psn_email_pref === '2' ?
							<ContactForm contactName={person.first_name + ' ' + person.last_name} contactId={person.person_id} />
							: ''}
					</div>
				</div>
				{person.hasEditAccess === 'me' ?
					<div className="alert alert-dark mt-3"><strong>Updates needed?</strong><br />
						You can <a href="https://www.scu.edu/login/?phonebook">edit your profile text, contact info, or preferred name</a> using the Phonebook application.
					</div>
					:
					person.hasEditAccess === 'org' ?
						<div className="alert alert-dark mt-3"><strong>Updates needed?</strong><br />
							You have permission to <a href={"/apps/phonebook/moderator/?person=" + person.person_id}>edit this person's information</a> using the Phonebook moderator application.
						</div>
						: ''



				}
			</div>
			)
		}
	}





}


function createMarkup(htm) {
	return { __html: htm };
}





export default Person;