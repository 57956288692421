import React  from 'react';
import CategoryItem from '../../utils/mapComponents/CategoryItem.js';


class Permit extends React.Component {
	constructor(props, context) {
      super(props, context);
     
	  this.state = {
          permit: props.permit || null,
          activePermit: props.activePermit || null
	  };
    }
    
    componentWillReceiveProps(props) {
       this.setState({activePermit:props.activePermit});
    }
           
    handleClick = () => {
        this.props.onPermitClick(this.props.permit.pid);
    }    

	render() {


         return (
           <li className="list-group-item permitLetter">
                <span onClick={this.handleClick}>
                    {this.state.activePermit === this.state.permit.pid? 
                        <i className="fa fa-caret-down"></i> 
                        :
                        <i className="fa fa-caret-right"></i> 
                    }
                    &nbsp;{this.state.permit.permit_letter} Permits<div className="small mb-1">{this.state.permit.short_descr}</div> 
                    {this.state.activePermit === this.state.permit.pid? 
                         <ul>
                            {this.state.permit.loc_ids.map((loc ) => {
									return <CategoryItem key={loc.content_id} loc_id={loc.loc_id} lat={loc.lat} lng={loc.lng} title={loc.title}/>
                                })}	
                             
                         </ul>

                    :''}
                </span></li>            
        );
		
	}
  }

  export default Permit;

  