import React from 'react';
import SEOLink from './../../utils/SEOLink.js';


class ListLocations extends React.Component {
	constructor(props, context) {
	  super(props, context);
  	 
	  this.state = {
            locs: props.locs
	  };
	}
  
     
	render() {

    
        return (
            <div>
            {this.state.locs.length > 0 &&	    
                <h4 className="text-sans-light" ref="locations">Locations</h4>
                }

                <div className="m-3">
                    {this.state.locs.map((loc, index) => {
                        return <div key={loc.loc_id}>
                                <div className="row h-100 p-3 card-body mb-1 bg-light">
                                    <div className="col-md-5">
                                        <div className="h5 text-sans-light"><SEOLink type="location" id={loc.loc_id} name={loc.buildname} /></div>
                                    
                                    </div>
                                    
                                </div>
                            </div>
                    })}									

                </div>
            </div>

        );
		
	}
  }

  export default ListLocations;
