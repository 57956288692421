import React, { Component } from "react";
import emergencyNums from "./../../static/emergency.json";
import { config } from "./../../config.js";

class PageEmergency extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loginResp: props.loginResp,
      wat: props.wat,
    };
  }

  render() {
    document.title = "Emergency Contacts - " + config.pageTitleBase;
    return (
      <div className="container py-3">
        <h3 className="text-sans-light mb-3">Emergency Contacts</h3>
        <hr />
        <div className="row">
          <div className="col-md-8">
            <ul className="list-group">
              <li className="list-group-item">
                <a href="tel:911">Fire, Police, Ambulance | Call 911</a>
              </li>
              <li className="list-group-item d-md-none">
                <a href="sms:911">Fire, Police, Ambulance | Text 911</a>
              </li>
              {emergencyNums.map((item, index) => (
                <li className="list-group-item" key={index}>
                  <a href={"tel:" + item.phone}>
                    {item.name} | {item.phone}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-4">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Crisis Services</h4>
                <p class="card-text">
                  If you are experiencing a personal crisis, the{" "}
                  <a href="https://www.scu.edu/cowell">Cowell Center</a> has
                  hourly crisis appointments available.
                </p>
                <a
                  href="https://www.scu.edu/cowell/crisis"
                  class="btn btn-primary"
                >
                  Crisis Services
                </a>
              </div>
            </div>
            <hr />
            <h5 className="text-sans-light">
              <a href="https://university-operations.scu.edu/campus-safety/emergency-planning/">
                Emergency Planning
              </a>
            </h5>
            <p>
              Emergency Planning provides training and awareness to our campus
              community to achieve a state of readiness across the University.
            </p>
            <h5 className="text-sans-light">
              <a href="https://university-operations.scu.edu/campus-safety/scu-campus-alert/">
                SCU Bronco Alert
              </a>
            </h5>
            <p>
              This campus alert system enables the University to send
              time-sensitive notifications during campus emergencies and for
              other safety-related messaging. Alerts are sent via cell phones,
              text message, and e-mail to all active members of the SCU
              community who are automatically enrolled in SCU Bronco Alerts.{" "}
              <a href="https://www.scu.edu/login/">MySCU Portal</a>.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PageEmergency;
