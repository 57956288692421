import React from 'react';

import {config} from '../../../config.js';
import exterior from '../../../static/exterior.jpg';

const MapWelcome = ( ) => (
    <div id="welcomeScreen">
        <img src={exterior} className="img-fluid w-100" alt="SCU Campus" />
        <p className="lead">Beautiful, sunny, and full of opportunity.</p>
        <p>Our campus is in the heart of Silicon Valley and spans 106 lush acres with world-renowned <a href={config.basePath+'/location/85'}>gardens</a> and state-of-the-art <a href={config.basePath+'/categories/academic'}>facilities</a> all surrounding our iconic <a href={config.basePath+'/location/42'}>Mission</a>.</p>
        <hr/>
        <p>Find <a href={config.basePath+'/parking'}>parking</a> and <a href={config.basePath+'/content/directions'}>directions</a> to campus, and see the map's <a href={config.basePath+'/content/visitor information'}>Visitor's Guide</a> for information about <a href={config.basePath+'/categories/residence-halls'}>residence halls</a>, <a href={config.basePath+'/categories/dining'}>dining locations</a>, <a href={config.basePath+'/categories/athletics'}>athletics facilities</a>, <a href={config.basePath+'/categories/aed'}>AED locations</a>, and more.
            </p>
            <p>Click or tap a building in the map for more location details, including floor maps.</p>
            <p>Browse the A-Z index or use the search form to find locations, organizations, and people at SCU.</p>
        Enjoy your visit!
    </div>
)

export default MapWelcome;