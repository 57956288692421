
import React, {  Component  } from 'react';
import {config} from '../../../config.js';

window.scuCampusLabels = [];
window.placedMarkers = [];
window.infoWindows = [];

window.SCU_MAPTYPE = 'SCU Campus';
window.FLR_MAPTYPE = 'Floor Plans';
window.STREET_MAP = 'Street Map';
window.scuLat = 37.349452
window.scuLng = -121.937642;
window.initialZoom = 17;
window.scuCustomMinZoom = 14;
window.scuCustomMaxZoom = 19;
window.scuLL = new window.google.maps.LatLng( window.scuLat , window.scuLng);
 
export class GoogleMap extends Component {

		constructor(props) {
				super(props);      
				this.state = {
					zoom: props.zoom || config.map.defaultZoom,						 
					fitToMarkers: props.fitToMarkers || true,						 
					initialCenter: { lat: props.lat || props.markerlat || config.map.scuLat , 
									 lng: props.lng || props.markerlng || config.map.scuLng},
					markers:null,
					overrideHeight: props.mapHeight || null,
					directionsFrom: props.directionsFrom || null,
					mapInitialized: false
			}    
			if  ( props.markerlat ) {
				this.state.markers = [{lat: props.markerlat, lng: props.markerlng }];

			} else if (typeof props.markers == 'object') {
				this.state.markers = props.markers;
			}
			this.loadMap = this.loadMap.bind(this)
			this.initializeMap = this.initializeMap.bind(this)

 
		}

		componentDidMount() {
			
			this.initializeMap() 
			this.loadMap();
		}

		componentDidUpdate() {
			
			this.loadMap();
		}
 

		initializeMap() {
			if ( !this.state.mapInitialized && window.google ) {
				let google = window.google;
				const maps = google.maps;
				const node =document.getElementById('gmap');
				if ( this.state.overrideHeight ) {

					node.style.cssText = "height: 400px !important"; //.height = this.state.overrideHeight +'px !important';
				}
				
				const center = new maps.LatLng(this.state.initialCenter.lat, this.state.initialCenter.lng);
				const mapConfig = Object.assign({}, {
					center: center,
					zoom: this.state.zoom,
					maxZoom: window.scuCustomMaxZoom,
					mapTypeId: window.SCU_MAPTYPE,
					mapTypeControlOptions: {
						mapTypeIds: [window.SCU_MAPTYPE, window.FLR_MAPTYPE, google.maps.MapTypeId.SATELLITE],
							style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
					  },
				 
				});
 				window.map = new maps.Map(node, mapConfig);

				
				var scumaptype = new google.maps.StyledMapType([], {
					name: window.SCU_MAPTYPE
				});
				var scuflrmaptype = new google.maps.StyledMapType([], {
					name: window.FLR_MAPTYPE
				});
					
				window.map.mapTypes.set(window.SCU_MAPTYPE, scumaptype);	
				window.map.mapTypes.set(window.FLR_MAPTYPE, scuflrmaptype);	
			
				
				function scumap() {	 } //empty constructor
				scumap.prototype.tileSize = new maps.Size(256,256);
				scumap.prototype.maxZoom = 19;
				scumap.prototype.name = 'SCU Campus';
				scumap.prototype.alt = 'SCU Campus Map';
				scumap.prototype.getTile = function(coord, zoom, ownerDocument) {
					var cordstr = ''+coord;
					var crdu = cordstr.replace('(','').replace(')','').split(",");
					if (isWithinRange(crdu[0],crdu[1],zoom)) {
						var myimg = config.map.tiles.standard + 'img_' +crdu[0].trim()+'_'+crdu[1].trim()+'.png';	

						let img = ownerDocument.createElement('IMG');
						img.width = this.tileSize.width;
						img.height = this.tileSize.height;
						img.alt = 'Tiled slice of campus map';
						img.src = myimg;
						return img;								
					} else {

						let img = ownerDocument.createElement('IMG');
						return img;			
					}

				};

				
				function scuflrmap() {	//empty constructor
				}
				scuflrmap.prototype.tileSize = new google.maps.Size(256,256);
				scuflrmap.prototype.maxZoom = window.scuCustomMaxZoom;
				scuflrmap.prototype.name = 'SCU Campus';
				scuflrmap.prototype.alt = 'SCU Campus Map';
				scuflrmap.prototype.getTile = function(coord, zoom, ownerDocument) {
					var img;
					var cordstr = ''+coord;
					var crdu = cordstr.replace('(','').replace(')','').split(",");
					if (isWithinRange(crdu[0],crdu[1],zoom)) {
						var myimg = config.map.tiles.floormap + 'img_' +crdu[0].trim()+'_'+crdu[1].trim()+'.png';	
							
						img = ownerDocument.createElement('IMG');
						img.width = this.tileSize.width;
						img.height = this.tileSize.height;
						img.src = myimg;
						return img;								
					} else {
						
						 img = ownerDocument.createElement('IMG');
						return img;			
					}
				};
				
				


				window.scuOverlay =  new scumap();
				window.map.overlayMapTypes.insertAt(0, window.scuOverlay);
				window.scuFlrOverlay =  new scuflrmap();


				google.maps.event.addListener(window.map, 'maptypeid_changed', window.mapTypeChgListener);

				this.mapInitialized = true;
				window.addHovers();	

			}
		}

		loadMap() {		
			

			if (window.google) {

				let google = window.google;
			
				google.maps.Map.prototype.scu_overlays = [];

				google.maps.Map.prototype.add_scu_overlay = function(content) {
					content.setMap(this);
					this.scu_overlays[this.scu_overlays.length] = content;
				};
				google.maps.Map.prototype.get_scu_overlays = function() {
					return this.scu_overlays;
				};
								
				google.maps.Map.prototype.clear_scu_overlays = function() {

					for(var i=0; i<window.placedMarkers.length; i++){
						window.placedMarkers[i].setMap(null);
					}
					for(  i=0; i<window.infoWindows.length; i++){
						window.infoWindows[i].setMap(null);
					}
					//hideHoverPin();
					//this.scu_overlays = new Array();
					//selLocation = '';
				
				};
			

		
				
				if (this.state.markers && this.state.markers.length > 0 ){
					var boundsHavePts = false;
					var bounds = new google.maps.LatLngBounds();
					bounds.extend( window.scuLL );

					for ( var k=0; k< this.state.markers.length; k ++ ) {
						var mkr =this.state.markers[k];
						if ( mkr.lat ) {
							var gmark = this.placeMarker(mkr.lat, mkr.lng, mkr.title);
							bounds.extend( gmark.getPosition());
							window.placedMarkers.push(gmark);
							boundsHavePts= true;
						}						
					}							
					if (  this.state.fitToMarkers && boundsHavePts) {
						window.map.fitBounds(bounds);
					}

					google.maps.event.addListener(window.map, "click", function(event) {
						window.closeAllInfoWindows();
					});

				}

				if ( this.state.directionsFrom ) {

					var directionsDisplay = new google.maps.DirectionsRenderer();

					var directionsService = new google.maps.DirectionsService();		
					directionsDisplay.setPanel(document.getElementById("directionsPanel"));
						
					var start = this.state.directionsFrom ;
					var end = config.directions_destination; //'37.351131,-121.937707'
										
					var request = {
						origin:start,
						destination:end,
						travelMode: google.maps.TravelMode.DRIVING
					};
					directionsService.route(request, function(result, status) {
						if (status === google.maps.DirectionsStatus.OK) {
													
							window.map.add_scu_overlay(directionsDisplay);			
							
							document.getElementById("directionsPanel").innerHTML = "";
							directionsDisplay.setDirections(result);
							
						} else { 
							alert("Error: the location could not be found");
						}
					});
				}

				

			}
		}

		
		placeMarker(lat,lng,title) {
			let markerPos =  {lat: parseFloat(lat), lng: parseFloat(lng)};
			let markerProps = {
				position:markerPos,
				map: window.map
			 }
			if ( title) {
				 markerProps.title = title;
				// var infowindow = new window.google.maps.InfoWindow({
				//	content: title
				//  });
			}
 
			var activeMarker = new window.google.maps.Marker(markerProps);
			if ( title) {

				var infowindow = new window.google.maps.InfoWindow() 
				window.google.maps.event.addListener( activeMarker,'click', (function( activeMarker,title,infowindow){ 
					return function() {
						window.closeAllInfoWindows();
						infowindow.setContent(title);
						infowindow.open(window.map,activeMarker);
					};
				})(activeMarker,title,infowindow));  
				window.infoWindows.push(infowindow);

			}
			return activeMarker;
		}



		render() { 
			return ( 
				<div className="GoogleMap">						
					<div id="gmap"  ></div>
				</div>
			)    
		}


}

window.closeAllInfoWindows =function() {
	for (var i = 0; i < window.infoWindows.length; i++ ) {  
		window.infoWindows[i].close();
	}
}


 
window.mapTypeChgListener = function() {

	var newTypeID = window.map.getMapTypeId();
	if (newTypeID === window.SCU_MAPTYPE){ 
		//toggleCampusLabels('block');
		if (!checkIfCampusInViewport()) {
			window.map.panTo(window.scuLL);
		}
		if (window.map.overlayMapTypes.length === 1)
			window.map.overlayMapTypes.removeAt(0);
		if (window.map.overlayMapTypes.length === 0)
			window.map.overlayMapTypes.insertAt( 0, window.scuOverlay);		
		
	} else if (newTypeID === window.FLR_MAPTYPE){ 

		//toggleCampusLabels('block');
		if (!checkIfCampusInViewport()) {
			window.map.panTo(window.scuLL);
		}
		if (window.map.overlayMapTypes.length === 1)
		window.map.overlayMapTypes.removeAt(0);
			
		if (window.map.overlayMapTypes.length === 0)
		window.map.overlayMapTypes.insertAt( 0, window.scuFlrOverlay);		
		
	} else {
	//	toggleCampusLabels('none');
		if (window.map.overlayMapTypes.length === 1)
			window.map.overlayMapTypes.removeAt(0);
	}
	


}

function checkIfCampusInViewport() {

	console.log('to do');
	return false;
}


function isWithinRange(x,y,zml) {					
	if (zml === 14 && x >= 2642 && x <= 2642 && y >= 6357 && y <= 6357 )
	return true;
		if (zml === 15 && x >= 5284 && x <= 5285 && y >= 12713 && y <= 12715 )
	return true;
		if (zml === 16 && x >= 10568 && x <= 10571 && y >= 25427 && y <= 25429 )
	return true;
		if (zml === 17 && x >= 21136 && x <= 21142 && y >= 50855 && y <= 50860 )
	return true;
		if (zml === 18 && x >= 42273 && x <= 42286 && y >= 101711 && y <= 101721 )
	return true;
		if (zml === 19 && x >= 84548 && x <= 84572 && y >= 203423 && y <= 203439 )
	return true;
	return false;	        
}			

window.ll =function (lat,lng) {
	return 	new window.google.maps.LatLng(lat,lng)
}

window.poly =function(ptsarr,id,lat,lng,lname,minzoom) {
	
	if (typeof minzoom === 'undefined' || minzoom === 0 )
		minzoom = 18;

		
	var mapLabel = new window.MapLabel({
	  text: lname,
	  position: new window.google.maps.LatLng(lat,lng),
	  map: window.map,
	  minZoom: minzoom,
	  zIndex:9998				  
	});
	window.scuCampusLabels.push(mapLabel);
	
	var thispoly= 	new window.google.maps.Polygon({
	  paths: ptsarr,
	  fillOpacity: 0,				 
	  strokeWeight:0,
	  map: window.map,
	  zIndex:9999				  
	});	
	
	window.google.maps.event.addDomListener(thispoly, 'mouseover', function() {
		thispoly.setOptions({strokeWeight: .5, fillOpacity: .2});
	});
	window.google.maps.event.addDomListener(thispoly, 'mouseout', function() {
		thispoly.setOptions({strokeWeight: 0, fillOpacity: 0});
	});
	window.google.maps.event.addDomListener(thispoly, 'click', function() {
		
		if ( window.map.getMapTypeId() === window.FLR_MAPTYPE ) {
			//window.launchFloorMaps(id);
			window.location.href = config.basePath + '/location/'+id+'/floors';
		} else {
			//window.load_content('loc',id,true,true);
			window.location.href = config.basePath + '/location/'+id;
		}
		window.selLocation = id;
	});
	
	return thispoly;
}


window.MapLabel =function(opt_options) {
  this.set('zIndex', 1e3);
  this.setValues(opt_options);
  
}
window.MapLabel.prototype = new window.google.maps.OverlayView();


window.MapLabel.prototype.onAdd = function() {
  var div = document.createElement('div');
  div.className = 'maplabelcont';
  div.innerHTML = '<div class=maplabel>'+this.text+'</div>';

  this.div_ = div;

  var panes = this.getPanes();
  panes.overlayLayer.appendChild(div);
}

window.MapLabel.prototype.draw = function() {

	this.setVisibility('block');	
	
	if (this.minZoom > window.map.getZoom() ||  (window.map.getMapTypeId() !== window.SCU_MAPTYPE && window.map.getMapTypeId() !== 'roadmap') ) {
		this.div_.style.left = '-4000px';
		this.setVisibility('none');	
	} else {
		this.setVisibility('block');	
		var overlayProjection = this.getProjection();
		var pos = overlayProjection.fromLatLngToDivPixel(this.position);
		var div = this.div_;
		div.style.left = (pos.x-60) + 'px';
		div.style.top = (pos.y+3) + 'px';
	}
	
}

window.MapLabel.prototype.onRemove = function() {
  this.div_.parentNode.removeChild(this.div_);
  this.div_ = null;
}

window.MapLabel.prototype.setVisibility = function(setTo) {
	if (typeof this.div_ != 'undefined' && this.div_ != null) {
	    this.div_.style.display = setTo;
	}
};



export default GoogleMap;