

const pburl = 'phonebook';
const mapurl = 'map';
//const maptesturl = 'map-new';




export const config = { 
    version: process.env.REACT_APP_VERSION,
    wsServer : process.env.REACT_APP_WEB_SERVICES_ROOT,
    imageRoot : process.env.REACT_APP_IMAGE_ROOT,
    rrr : process.env.REACT_APP_GMAP_TILES_STD,
    pageTitleBase: 'SCU Phonebook',
    rootOrg: 224,
    basePath : '',
    map : {
        scuLat: 37.349452,
        scuLng: -121.937642,
        defaultZoom: 17,
        hoversJS: process.env.REACT_APP_MAP_HOVERAREAS,
        tiles: {
                'standard': process.env.REACT_APP_GMAP_TILES_STD,
                'floormap': process.env.REACT_APP_GMAP_TILES_FLR
            }
    },
    error_codes: {
            '404' : 'Not Found'
        },
    recaptcha: {sitekey:  process.env.REACT_APP_RECAPTCHA_SITEKEY },
    APP: 'phonebook',
    contentSpecial: {'driving':'943'},
    scu_address: '500 El Camino Real, Santa Clara, CA 95053',
    directions_destination: 'Palm Dr, Santa Clara, CA 95050, USA'

};


var basepathPos = window.location.pathname.indexOf(pburl);
if ( basepathPos > -1 ) {
    config.basePath = window.location.pathname.substring(0, basepathPos + pburl.length  );
}

var basepathPosMap = window.location.pathname.indexOf(mapurl);
if ( basepathPosMap > -1 ) {
    config.APP = 'map';
    config.basePath = window.location.pathname.substring(0, basepathPosMap + mapurl.length  );
    config.pageTitleBase = "SCU Campus Map";
}

/*var basepathPosMap = window.location.pathname.indexOf(maptesturl);
if ( basepathPosMap > -1 ) {
    config.APP = 'map';
    config.basePath = window.location.pathname.substring(0, basepathPosMap + maptesturl.length  );
    config.pageTitleBase = "SCU Campus Map";
}*/
console.log(config)
