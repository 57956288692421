
import React from 'react';
import TopNav from './TopNav';

const Phonebook_head     = ( ) => (

<header className="header-legacy bg-primary pt-lg-5">
	<a href="#appBody" class="sr-only sr-only-focusable bg-white">Skip to main content <i class="far fa-arrow-down"></i></a>
    <TopNav />
    <div className="container d-flex align-items-center justify-content-center justify-content-lg-between py-3">
      <div className="d-flex align-items-center py-2">

        <div className="text-center text-lg-left">
        	<div id="scuLoginButton"></div>
            <h1 className="sr-only">Santa Clara University</h1>	          
			<h2 className="text-light wordmark m-0">Phonebook</h2>
			<div className="h6 text-primary text-sans-light wordmark m-0">
				<a className="text-light" href="/phonebook">Faculty and Staff Directory</a>
			</div>

		</div>
      </div>
    </div>
    
	<nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow initial nav-dark nav-custom">
		<div id="navbarMenu" className="container">
			<button className="navbar-toggler px-3 py-2" type="button" data-toggle="collapse" data-target="#navbarCustom" aria-controls="navbarCustom" aria-expanded="false" aria-label="Toggle navigation">
				<span className="text-uppercase">menu</span>
			</button>
			<div className="collapse navbar-collapse" id="navbarCustom">
				<ul className="navbar-nav mr-auto py-3 py-lg-0 w-75">
					<li className="nav-item"><a className="nav-item nav-link text-uppercase px-3" href="/phonebook/emergency-contacts">Emergency Contacts</a></li>
					<li className="nav-item"><a className="nav-item nav-link text-uppercase px-3" href="/phonebook/Housing">Housing</a></li>
					<li className="nav-item"><a className="nav-item nav-link text-uppercase px-3" href="/phonebook/print">Print</a></li>
					<li className="nav-item"><a className="nav-item nav-link text-uppercase px-3" href="/phonebook/content/faq">FAQ</a></li>
					
					<li className="nav-item"><a className="nav-item nav-link text-uppercase px-3" href="/map">Campus Map</a></li>
					<li className="nav-item d-lg-none"><div className="dropdown-divider"></div><a className="nav-link text-uppercase px-3" href="https://www.scu.edu/">SCU Home</a></li>
					<li className="nav-item d-lg-none"><a className="nav-link text-uppercase px-3" href="https://www.scu.edu/about">About SCU</a></li>

					<li className="nav-item d-lg-none"><a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/academics/">Academics</a></li>
					<li className="nav-item d-lg-none"><a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/admission/">Admission</a></li>
					<li className="nav-item d-lg-none"><a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/athletics/">Athletics</a></li>
					<li className="nav-item d-lg-none"><a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/campus-life/">Campus Life</a></li>
					<li className="nav-item"><a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/giving/">Giving</a></li>
					<li className="nav-item d-lg-none"><a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/global/">Global</a></li>
					<li className="nav-item d-lg-none"><a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/news-and-events/">News &amp; Events</a><div class="dropdown-divider d-lg-none my-3"></div></li>
					<li className="nav-item d-lg-none"><a className="nav-link text-uppercase px-3" href="https://www.scu.edu/services">Offices & Services</a></li>
					<li className="nav-item d-lg-none"><a className="nav-link text-uppercase px-3" href="https://www.scu.edu/schools-and-centers">Schools & Centers</a></li>
				</ul>
				
			</div>
		</div>
	</nav>
  </header>

 )
export default Phonebook_head;