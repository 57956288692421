import React from 'react'

function NameCoachBtn(props) {

  const audio = new Audio(props.nameCoachURL);

  const handleBtnClicked = () => {
    audio.play();
  }

  return (
    <i
      className="fas fa-play-circle fa-xs ml-2"
      style={{ fontSize: "1.5rem", cursor: "pointer" }}
      title='Pronounce'
      onClick={handleBtnClicked}>
      <span className="sr-only">Pronounce My Name</span>
    </i>
  )
}

export default NameCoachBtn