import React from 'react';
import {config} from './../../../config.js';
import SEOLink from './../../utils/SEOLink.js';
import './../../../style/Person.css';
import Icon from './../../utils/Icon.js';
import ExternalLink from '../../utils/ExternalLink.js';
import LocationLink from './../../utils/LocationLink';

class ListOrgs extends React.Component {
	constructor(props, context) {
	  super(props, context);
  	 
	  this.state = {
          orgs: props.orgs
	  };
	}
   
	render() {

        return (
            <div>
            {this.state.orgs.length > 0 &&	    
                <h4 className="text-sans-light" ref="organizations">Organizations</h4>
                }
                <div className="m-3">
                    {this.state.orgs.map((org, index) => {
                        return <div key={org.org_id+index}>
                                <div className="row h-100 p-3 card-body mb-1 bg-light">
                                    <div className="col-md-5">
                                        <span className="h5  text-sans-light"><SEOLink type="org" id={org.org_id} name={org.orgRealName? org.orgRealName: org.name} linktext={org.name} /></span>
                                        {org.parentorgid !== config.rootOrg && org.parentorgname && org.parentorgname.trim() !== ''? 
                                            <div className="muted small pl-1">in: <SEOLink type="org" id={org.org_id} name={org.parentorgname} className="muted" /></div>
                                            :<></>}
                                    </div>
                                    <div className="col-md-7">                                     
                                        {config.APP === 'map' && org.locstring !== ''? 
                                            <div>
                                                <Icon name="map" sr="Location" /> 
                                                <LocationLink data={org}  />
                                            </div> :''}
                                        {org.orgHRphone? <> <Icon name="phone" sr="Phone Icon" /> <a href={'tel:'+org.orgHRphone}>{org.orgHRphone}</a><br/></>:<></>}
                                     
                                        {org.site_url && config.APP !== 'map'? 
                                            <div> <ExternalLink className="btn btn-sm btn-light" url={org.site_url} text="website"  /></div>
                                        :<></>}
                                        
                                    </div>
                                </div>
                            </div>
                    })}					
                    
                </div>
            </div>    
        );
		
	}
  }

  export default ListOrgs;
