import React from 'react';
import SEOLink from './SEOLink.js'
import { config } from '../../config.js';

const OrgBreadcrumb = (props) => {
    
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {props.bc.map((org, index) => {
                    if ( !org ) {
                        return <></>
                    } else {
                        return (<li className="breadcrumb-item" key={index}>
                            { parseInt(org.org_id) === config.rootOrg    ?
                            'SCU'
                            :   <SEOLink type="org" name={org.name} id={org.org_id} /> 
                        }
                                </li>)


                    }
                        })}
                
            </ol>
            </nav>
    
        )
}

export default OrgBreadcrumb;