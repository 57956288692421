
import React, { Component } from 'react';
import TopNav from './TopNav';
import Dropdown from  'react-bootstrap/Dropdown';
import SearchBox from './../../../components/utils/SearchBox.js';
import {config} from './../../../config.js';

const letters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']



class MapHead extends Component {
	render() {	 
       
       return (
		  <>
            <header className="header-legacy bg-light pt-lg-5">
		   		<a href="#appBody" className="sr-only sr-only-focusable bg-white">Skip to main content <i className="far fa-arrow-down"></i></a>
                <TopNav />

                <div className="container d-flex align-items-center justify-content-center justify-content-lg-between py-3">
                <div className="d-flex align-items-center py-2">
                    <div className="text-center text-lg-left">
                        <div id="scuLoginButton"></div>
                        <h1 className="sr-only">Santa Clara University</h1>	          
                        <h2 className="text-sans-light wordmark m-0">
                            <a className="text-dark" href="/map">Campus Map</a>
                        </h2>
                        <div className="h6 text-dark text-sans-light wordmark m-0">
                            Santa Clara University
                        </div>
                    </div>
                </div>
                </div>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow initial nav-dark nav-custom">
                    <div id="navbarMenu" className="container">
                        <button className="navbar-toggler px-3 py-2" type="button" data-toggle="collapse" data-target="#navbarCustom" aria-controls="navbarCustom" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fas fa-bars"><span className="sr-only">Menu</span></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCustom">
                            <ul className="navbar-nav mr-auto py-3 py-lg-0 w-75">
                                
                                <li className="nav-item" key="1"><a className="nav-item nav-link text-uppercase px-3" href={config.basePath+'/parking'}>Parking</a></li>
                                <li className="nav-item" key="2"><a className="nav-item nav-link text-uppercase px-3" href={config.basePath+'/content/directions'}>Directions</a></li>
                                <li className="nav-item" key="3"><a className="nav-item nav-link text-uppercase px-3" href={config.basePath+'/content/visitorinformation'}>Visitor's Guide</a></li>
                                <li className="nav-item" key="4">
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic" className="bg-dark">BROWSE A-Z</Dropdown.Toggle>
                                        <Dropdown.Menu className="ddBrowse">
                                        {letters.map((letter,key) =>
                                            <span key={key}>
                                                <a href={config.basePath+'/browse/'+letter}>{letter}</a> 
                                                {key> 0 && (key+1)%6===0? <br/>:''}
                                            </span>
                                            )}
                                        
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className="nav-item" key="5"><a className="nav-item nav-link text-uppercase px-3" href={config.basePath+'/content/faq'}>FAQ</a></li>

                                <li className="nav-item d-lg-none"><div className="dropdown-divider"></div><a className="nav-link text-uppercase px-3" href="https://www.scu.edu/">SCU Home</a></li>
								<li className="nav-item d-lg-none"><a className="nav-link text-uppercase px-3" href="https://www.scu.edu/about">About SCU</a></li>

								<li class="nav-item d-lg-none"><a class="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/academics/">Academics</a></li>
								<li class="nav-item d-lg-none"><a class="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/admission/">Admission</a></li>
								<li class="nav-item d-lg-none"><a class="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/athletics/">Athletics</a></li>
								<li class="nav-item d-lg-none"><a class="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/campus-life/">Campus Life</a></li>
								<li class="nav-item"><a class="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/giving/">Giving</a></li>
								<li class="nav-item d-lg-none"><a class="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/global/">Global</a></li>
								<li class="nav-item d-lg-none"><a class="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/news-and-events/">News &amp; Events</a><div class="dropdown-divider d-lg-none my-3"></div></li>
								<li className="nav-item d-lg-none"><a className="nav-link text-uppercase px-3" href="https://www.scu.edu/services">Offices & Services</a></li>
								<li className="nav-item d-lg-none"><a className="nav-link text-uppercase px-3" href="https://www.scu.edu/schools-and-centers">Schools & Centers</a></li>
                            </ul>

                            <SearchBox mapRender={1}  doSearch={this.props.doSearch}  />
                        </div>
                    </div>
                </nav>
            </header>					
						
		  </>

       )                     
}}

export default MapHead;