import React from 'react';
import Button from 'react-bootstrap/Button';
import BSModal from 'react-bootstrap/Modal';

class Modal extends React.Component {
    constructor(props, context) {
      super(props, context);
  
      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.state = {
        show: this.props.show,
        buttons: this.props.buttons || [{ action: props.toggler, text: 'OK'}],
        title: this.props.title || '',
        body: this.props.children
      };
    }


    componentDidUpdate(prevProps) {
        if (this.props.show !== prevProps.show) {
             this.setState({show: this.props.show })
        }
        if (this.props.children !== prevProps.children) {
            this.setState({body: this.props.children })
       }
       if ( !areEqualShallow(this.props.buttons,prevProps.buttons) ){
            this.setState({buttons: this.props.buttons })
       }
}    
  
    handleClose() {
        this.props.toggler();
//     this.setState({ show: false });
    }
  
    handleShow() {
      //this.setState({ show: true });
      this.props.toggler();
    }

    render() {
         
      return (
        <>
          <BSModal show={this.state.show} onHide={this.props.toggler} size='lg'>
            <BSModal.Header closeButton>
              <BSModal.Title>{this.state.title}</BSModal.Title>
            </BSModal.Header>
            <BSModal.Body>{this.state.body}</BSModal.Body>
            <BSModal.Footer>
                {this.state.buttons.map((btn, index) => {
                    return <Button key={index} variant={btn.color||'primary'} onClick={btn.action}>
                            {btn.text}
                        </Button>
                })}			              
            </BSModal.Footer>
          </BSModal>
        </>
      );
    }
  }
  function areEqualShallow(a, b) {
    for(var key in a) {
        if(!(key in b) || a[key] !== b[key]) {
            return false;
        }
    }
    for( key in b) {
        if(!(key in a) || a[key] !== b[key]) {
            return false;
        }
    }
    return true;
}
  export default Modal;