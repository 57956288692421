import React, { Component } from 'react';
import { Link }  from "react-router-dom";
import {config} from './../../config.js';
import Loading from './../utils/Loading.js';
import SEOLink from './../utils/SEOLink.js'
import Person from './../pages/Person.js';
import Organization from './../pages/Organization.js';


class SearchResults extends Component {

    constructor(props) {
        super(props)
        this.state  = {
            
            isSEO: props.isSEO,
            searchValue: props.match.params.query,
            searchResults: null,
            initialized: null
        }
        this.fetchSearchRes = this.fetchSearchRes.bind(this);

    }
    componentDidMount() {
         this.fetchSearchRes(this.state.searchValue);
    }
    
    componentWillReceiveProps (props) {
        this.fetchSearchRes(props.match.params.query);
    }

    fetchSearchRes(forValue) {
        if ( forValue !== this.state.searchValue || ! this.state.initialized) {
            this.setState({searchValue:forValue, searchResults: null, initialized: true});
           
            const jsonp = require('jsonp');

			jsonp(config.wsServer+'/apps/ws/phonebook/public/search/?q='+encodeURIComponent(forValue)+'&isSEO='+this.state.isSEO, null, (err, data) => {
				if (err) {
					this.setState({  error:err.message});
				} else {
					if ( data.title ==='ok') {
						this.setState({searchValue:forValue, searchResults: data.results, initialized: true});         
					} else {
						this.setState({  error: data.results});               
					}

				}
			});
			this.props.updateRT('org');


        }


    }

    render() {
        if ( typeof this.state.searchResults === 'undefined' || this.state.searchResults === null ) {
            return (
                <Loading />
            )
        } else if ( typeof  this.state.searchResults.person !== 'undefined' ) {
            return (
                <Person prefetchedData={this.state.searchResults.person} />
            )
        } else if ( typeof  this.state.searchResults.org !== 'undefined' ) {
            return (
                <Organization prefetchedData={this.state.searchResults.org} />
            )
        } else if ( typeof  this.state.searchResults.organizations == 'undefined' ) {
            return (
                <div>No search results.</div>
            )
        } else {        
            document.title = 'Search Results - ' + config.pageTitleBase;            
            return (
            	<div>
	                <div className="container py-3 SearchResults">  
	                   <h3 className="text-sans-light">Search</h3>
	                   <p className="lead">Your search for <em>{this.state.searchValue}</em> found the following:</p>                   
	                   <div className="row"> 
	                    <div className="grid-item mb-3 grid-01 col-md-4 col-sm-6">
	                        <div className="card h-100">
	                          <div className="card-block">
	                            <h4 className="h5 pl-3 pt-2 text-sans-light">Organizations</h4>
	                          </div>
	                           <ul className="list-group list-group-flush">
	                                { this.state.searchResults.organizations.length === 0 ? <li className="list-group-item">No results in organizations</li>:''}
	                                { this.state.searchResults.organizations.map(  (item,index) =>  
	                                    <li className="list-group-item" key={index}><small><SEOLink type="org" id={item.id} name={item.matchtitle} /></small></li>                                                              
	                                )}    
	                           </ul>
	                        </div>
	                    </div>
	                    <div className="grid-item mb-3 grid-01 col-md-4 col-sm-6">
	                        <div className="card h-100">
	                          <div className="card-block">
	                            <h4 className="h5 pl-3 pt-2 text-sans-light">People</h4>
	                          </div>
	                            <ul className="list-group list-group-flush">
	                            { this.state.searchResults.people.length === 0 ? <li className="list-group-item">No results in people</li>:''}
	                                { this.state.searchResults.people.map(  (item,index)  =>  
	                                    <li className="list-group-item" key={index}><small><SEOLink type="person" id={item.id} name={item.seoname} linktext={item.matchtitle} /></small></li>                    
	                                )}    
	                            </ul> 
	                        </div>
	                    </div>
	                    <div className="grid-item mb-3 grid-01 col-md-4 col-sm-6">
	                        <div className="card h-100">
	                          <div className="card-block">
	                            <h4 className="h5 pl-3 pt-2 text-sans-light">Locations</h4>
	                          </div>
	                            <ul className="list-group list-group-flush">
	                                { this.state.searchResults.locations.length === 0 ? <li className="list-group-item">No results in locations</li>:''}
	                               { this.state.searchResults.locations.map(  (item,index)  =>  
	                                    <li className="list-group-item" key={index}><small><Link to={"/location/"+item.id}>{item.matchtitle}</Link></small></li>                    
	                                    )}    
	                            </ul>
	                            </div>
	                    </div>
	                    </div>            
	                      
	                </div>
                </div>
            )
        }
        
    }
}


export default SearchResults;