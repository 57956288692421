import React from 'react';
import {config} from './../../config.js';

/*
    This component builds a human readable or SEO link. For example, if it's given the name property "Academic Technology", it will format
    this as "Academic-Technology". It will also attempt to build the link with the same path you are currently using. So if the browser is at
    https://dev01.scu.edu/apps/phonebook/Tony-Pehanich, we'd want a link to the Academic Technology org to be
    https://dev01.scu.edu/apps/phonebook/Academic-Technology, rather than just https://dev01.scu.edu/Academic-Technology.
    This allows us to use the same code on phonebook.scu.edu as www.scu.edu/phonebook and dev01.scu.edu/apps/phonebook
*/

const SEOLink = (props) => {
    var linkDest = config.basePath;
    if ( typeof props.name == 'undefined' || props.name === '' || props.name === null ){
        return <></>
    }
    

    var safeName = encodeURI(props.name.trim().replace(/ /g,'-').replace(/,/g,''));
    if ( props.type === 'org' ) {
        if ( props.name.indexOf('-') > -1 ) {
            linkDest += "/org/"+props.id+'/'+safeName;

        } else {
            linkDest += "/"+safeName;

        }

    } else if ( props.type === 'location' ) {
        if ( props.name.indexOf('-') > -1 ) {
            linkDest += "/location/"+props.id+'/'+safeName;

        } else {
            linkDest += "/location/"+safeName;

        }

    } else {
        if ( props.isUnique ) {
            linkDest += "/"+safeName;
        } else {
            linkDest += "/"+props.id+'/'+safeName;
        }
    }
    return (<a href={linkDest} className={props.className}>{props.linktext? props.linktext: props.name}</a>)
}

export default SEOLink;