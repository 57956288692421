
import React from 'react';
import {  Link }  from "react-router-dom";


class MyProfile extends React.Component {
	constructor(props, context) {
	  super(props, context);
  	 
	  this.state = {
        loginResp: props.loginResp,
        wat: props.wat
	  };
	}
 	
	render() {
        if ( this.state.loginResp && this.state.loginResp.data  ) {

            if (  this.state.loginResp.data.last_name ) {
                const me = this.state.loginResp.data;
                return (
                    <div className="IfLogin">
                    {me.picture_filename?
                            <img src={me.picture_filename} alt={me.first_name+' '+me.last_name} className="float-right profileImage border border-light mr-3 rounded-circle" />
                            :''}
                        <h4 className="h5 card-title text-sans-light">My Profile</h4>
                        <h5 className="">{me.pertitle} {me.first_name} {me.middle}  {me.last_name} {me.suffix} <small class="text-muted">{me.pronouns}</small></h5>
                        <h6 className="card-title">{me.email}</h6>
                        <div className="card-text">
                            <div className="text-center">
                               
                                
                            </div>
                            <div className="media-body">
                                {me.positions.map((pos, index) => {
                                    return (
                                        <div key={index} className="small"><hr />
                                            <strong>{pos.title}</strong> - <Link to={'/org/'+pos.org_id}>{pos.orgname}</Link><br/>
                                            <div className="float-right">{pos.locstring}</div>
                                            <span className="text-muted">{pos.phstring}<br/></span>
                                        </div>
                                    )
                                    
                                })}	
                                 <hr/>
                                 <p>You can edit your profile image, name, description and other contact details in the Phonebook Representative app.</p>
	                             <div className="text-center">
	                             	<a href={'/apps/phonebook/moderator/?person='+me.person_id} className="btn btn-primary">Edit My Profile</a>
	                             </div>
                            </div>
                        </div>
                    </div>
                );
            
            } else {
                return (
                    <div className="IfLogin">
                        <h4>Logged In</h4>
                        <p>You are logged in, so you will see all available contact information in the phonebook.</p>

                    </div>
                )

            }
        } else {
            return (
                <div className="IfLogin">
                    <h4 className="h5 text-sans-light">SCU Users</h4>
                    <p>
                        If you are a current SCU student, faculty, or staff you can 
                        Login to the phonebook in order to see all information available. This may include more detailed phone number, locations, and descriptions than those available to the general public.

                    </p>
                    <h4 className="h5 text-sans-light">SCU Faculty &amp; Staff</h4>
                    <p>
                        If you are a current SCU faculty, or staff you can edit your contact information and how your information appears in the phonebook.
                        Login with your SCU account to continue.
                    </p>
                    <div className="text-center">
                        <Link to='/login' onClick={this.props.login} className="btn btn-primary"> Login </Link>
                    </div>
                    
                </div>
            );

        }
		
	}
  }

  export default MyProfile;
