import React, { Component } from 'react';
import {config} from '../../config.js';
import Error from '../utils/Error.js';
import Loading from '../utils/Loading.js';
import GoogleMap from '../utils/mapComponents/GoogleMap.js';
import ImageSlideshow from './location/ImageSlideshow.js';

/*
	There are a couple "Special Case" pieces of content:
		- "FAQ", ie. /map/content/faq - this is the help documentation, and won't show the map as it normally would
		- Directions ie. /map/content/directions - will add the "get direction form"
*/	
class Parking extends Component {
		constructor(props) {
			super(props);      
			this.state = { name: null,			
				error: null,
				id: null,
				obDetails: props.prefetchedData || {},
				directionsInput:'',
				lookedUpDirections:null
				
			} ;       
			if ( props && props.match && props.match.params && props.match.params.id ) {
				this.state.id = props.match.params.id;
			}
			if ( config.APP === 'map' && this.state.id.toLowerCase()  === 'faq') {
				this.state.id = 'MapFAQ';
			}
			this.getDirections = this.getDirections.bind(this)
		}
 
		componentDidMount() {

			if ( !this.state.obDetails.length ) {
				const jsonp = require('jsonp');
				jsonp(config.wsServer +'/apps/ws/phonebook/public/content/'+this.state.id, null, (err, data) => {
					if (err) {
						this.setState({  error:err.message});
					} else {
						if ( data.title ==='ok') {
							this.setState({obDetails: data.results, error: null});               
						} else {
							this.setState({  error: data.results});               
						}
					}
				});
				this.props.updateRT('person');
			}
		}

		getDirections() {

			console.log('y')
			 this.setState({lookedUpDirections: this.state.directionsInput})
		}

	 
		render() {

			
			if (  this.state.error != null) {
				return (  <Error message={this.state.error} />  );
			} else if ( typeof this.state.name == 'undefined' ) {
				return (
					<div className="Content">
						Error: no content specified
					</div>          
				);
			} else if ( typeof this.state.obDetails.content === 'undefined' ) {
				return ( <Loading />  );
			} else if (this.state.id.toLowerCase() === 'faq' || this.state.id.toLowerCase() ===  'mapfaq' || this.state.id.toLowerCase() ===  "visitorinformation") {
				
				document.title = this.state.obDetails.content.title + ' - ' + config.pageTitleBase;
				
				return (
					<div className="Content container py-3">
						<div dangerouslySetInnerHTML={createMarkup(this.state.obDetails.content.descr)}></div>
					</div>
				)

			} else {
				
				document.title = this.state.obDetails.content.title + ' - ' + config.pageTitleBase;            
 
				
				return (
					<div className="Content container py-3 container-map align-items-center justify-content-center justify-content-lg-between py-2">
						<div className="row">
							<div className="col-md-4 mapLeft">
							    <a href="#descr" className="text-warning h5 float-right mt-2 ml-3 d-lg-none" data-toggle="collapse"><i className="fas fa-info-circle" aria-hidden="true"></i><span className="sr-only">Description</span></a>
								<h3>{this.state.obDetails.content.title}</h3>
								<hr />							
								<div id="descr" className="collapse p-3 bg-light d-lg-block" dangerouslySetInnerHTML={createMarkup(this.state.obDetails.content.descr)}></div>

								<ImageSlideshow images={this.state.obDetails.content.images} />
								{this.state.obDetails.content.content_id === config.contentSpecial.driving?
									<div>
										 
										<p><label>Get Directions From:
											<input type="text" className="form-control" name="directiodirectionsInputnsFrom" value={this.state.directionsInput}  onChange={e => this.setState({ directionsInput: e.target.value })} />
											</label>
										</p>
									 
										<input type="button" onClick={this.getDirections} name="go" className="btn btn-primary" value="Map It »" id="submit" />
										<div id="directionsPanel">
										</div>
									</div>
									:<></>}
							</div>
							<div className="col-md-8 mapRight"> 
								<GoogleMap markerlat={this.state.obDetails.content.lat} markerlng={this.state.obDetails.content.lng} directionsFrom={this.state.lookedUpDirections} key={this.state.lookedUpDirections} /> 
							</div>
						</div>
					 </div>
					 
				)			
			}		 
		}
	}

	
	function createMarkup(htm) {
		return {__html: htm};
	}	

export default Parking;