
import React from 'react';

const TopNav     = ( ) => (


    <nav className="navbar navbar-expand-lg navbar-light bg-white d-none d-lg-block py-0 fixed-top nav-fixed initial nav-users" id="navbarUsers">
      <div className="container">
        <div className="dropdown nav-brand-mini" id="navBrandMini">
          <button className="btn btn-light dropdown-toggle d-flex align-items-center" type="button" id="navBrandMiniBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="icon-mission mr-2"></span> <span className="text-uppercase">scu.edu</span>
          </button>
          <div className="dropdown-menu bg-primary shadow" aria-labelledby="navBrandMiniBtn">
            <div className="container">
              <ul className="nav nav-pills flex-nowrap flex-row py-1">
                <li className="nav-item">
                <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/">Home</a>
              </li>
                    <li className="nav-item">
                <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/aboutscu/">About SCU</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/academics/">Academics</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/admission/">Admission</a>
              </li>
              <li className="nav-item">
              <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/athletics/">Athletics</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/campus-life/">Campus Life</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/giving/">Giving</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/global/">Global</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/news-and-events/">News &amp; Events</a>
              <div className="dropdown-divider d-lg-none my-3"></div>
            </li>
            <li className="nav-item d-lg-none">
            <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/students/">Students</a>
            </li>
            <li className="nav-item d-lg-none">
              <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/faculty/">Faculty</a>
            </li>
            <li className="nav-item d-lg-none">
              <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/family/">Families</a>
            </li>
            <li className="nav-item d-lg-none">
              <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/alumni/">Alumni</a>
            </li>
            <li className="nav-item d-lg-none">
              <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/visitors/">Visitors</a>
            </li>
            <li className="nav-item d-lg-none">
              <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/services/">Offices &amp; Services</a>
            </li>
            <li className="nav-item d-lg-none">
              <a className="nav-link text-white text-uppercase px-3" href="https://www.scu.edu/centers/">Schools &amp; Centers</a>
            </li>
              </ul>
            </div>
          </div>
        </div>
        <ul className="navbar-nav ml-auto">
          

          <li className="nav-item dropdown mr-1">
            <a className="nav-link collapsed text-uppercase px-3" href="https://www.scu.edu/services/">
              Offices &amp; Services
            </a>
          </li>
          <li className="nav-item dropdown ml-1">
            <a className="nav-link collapsed text-uppercase px-3" href="https://www.scu.edu/schools-and-centers/">
              Schools &amp; Centers
            </a>
          </li>
          <li className="nav-item dropdown dropdown-search ml-1">
            <a className="nav-link" href="/search" id="dropdownSearch" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span className="sr-only">Search SCU</span> <i className="fas fa-search text-primary"></i>
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownSearch">
              <form className="p-3" method="get" action="https://www.scu.edu/search/">
                  <div className="input-group">
                    <label htmlFor="q" className="sr-only">Search SCU</label>
                    <input className="form-control" id="q" name="q" type="search" placeholder="Search SCU" aria-label="Search" />
                    <div className="input-group-append">
                      <button className="input-group-text btn btn-outline-primary" type="submit">
                        <span className="sr-only">Search SCU</span>
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
              </form>
            </div>
          </li>
        </ul>
      </div>
    </nav>


 )
export default TopNav;