import React, { Component } from 'react';
import { config } from './config.js';
import SearchBox from './components/utils/SearchBox.js';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './style/index.css';
import Login from './components/pages/Login.js';
import Error from './components/utils/Error.js';


import Browse from './components/pages/Browse.js';
import Category from './components/pages/Category.js';
import Content from './components/pages/Content.js';
//import Help from './components/pages/Help.js';
import Location from './components/pages/Location.js';
import MapLanding from './components/pages/MapLanding.js';
import Organization from './components/pages/Organization.js';
import PhonebookLanding from './components/pages/PhonebookLanding.js';
import Person from './components/pages/Person.js';
import Parking from './components/pages/Parking.js';
import PageEmergency from './components/pages/PageEmergency.js';
import PageHousing from './components/pages/PageHousing.js';
import Print from './components/pages/Print.js';
import SearchResults from './components/pages/SearchResults.js';
import PhonebookHead from './components/pageLayout/header/phonebook.js';
import MapHead from './components/pageLayout/header/map.js';
import Footer from './components/pageLayout/footer/default';
import NameCoachUpdate from './components/pages/NameCoachUpdate.js';
import { loadReCaptcha } from 'react-recaptcha-v3'

class App extends Component {


	constructor(props) {
		super(props);

		this.state = {
			page: 'home',
			pageData: {},
			loginResp: {},
			resultType: null,
			error: null
		}

		this.login = this.login.bind(this);
		this.logout = this.logout.bind(this);
		this.doSearch = this.doSearch.bind(this);
		this.updateRT = this.updateRT.bind(this);
	}


	componentDidMount() {
		loadReCaptcha(config.recaptcha.sitekey );
		
		const script = document.createElement("script");

		script.src = "https://assets.scu.edu/public/instant-notification.js";
		script.async = false;

		document.body.appendChild(script);

		try {
			const jsonp = require('jsonp');
			//Determine if the user is logged in and get username if so
			jsonp(config.wsServer + '/apps/ws/phonebook/public/login?return=' + encodeURIComponent(window.location.href), null, (err, data) => {
				if (err) {
					this.setState({ error: err.message });
				} else {

					if (data.title === 'ok') {
						this.setState({ loginResp: data.results, error: null });
					} else {
						this.setState({ error: data.results });
					}

				}
			});

		} catch (error) {
			console.error(error);
		}

	}

	doSearch(newResultType) {
		//if we are on the same resultType page (person to a different person) then we need to force reload of the page to get the new result
		if (this.state.resultType !== null && newResultType === this.state.resultType) {
			window.location.reload();
		} else {
			this.setState({ resultType: newResultType });
		}
	}
	updateRT(newResultType) {
		this.setState({ resultType: newResultType });
	}

	showModal(title, message) {
		this.modalChg.current.set({ title: title, message: message, show: true })
	}

	login(e) {
		e.preventDefault();
		window.location.href = this.state.loginResp.loginURL;
	}
	logout(e) {
		e.preventDefault();
		window.location.href = this.state.loginResp.logoutURL;
	}

	render() {
		var LandingPage;
		var Header;
		if (config.APP === 'map') {
			document.title = 'Campus Map - Santa Clara University';
			LandingPage = MapLanding;
			Header = MapHead;
		} else {
			LandingPage = PhonebookLanding;
			Header = PhonebookHead;
		}

		
		return (
			<>
				<Router className="App" basename={config.basePath}>

					<Header doSearch={this.doSearch} />
					{config.APP === 'phonebook' ? <SearchBox loginStatus={this.state.loginStatus} login={this.login} doSearch={this.doSearch} /> : <></>}
					<Login loginResp={this.state.loginResp} login={this.login} logout={this.logout} />

					<div id="appBody">
						{this.state.loginResp.stat ?
							<>
								<Route path="/" exact
									render={(props) => <LandingPage {...props} login={this.login} wat={this.state.wat} loginResp={this.state.loginResp} updateRT={this.updateRT} />} />
								<Route path="/login"
									render={(props) => <Login {...props} forceLogin={1} loginResp={this.state.loginResp} />} />
								<Route path="/print/:orgs?"
									render={(props) => <Print {...props} login={this.login} loginResp={this.state.loginResp} />} />
							</>
							: ''}

						<Switch >
							<Route path="/print/:orgs?" render={(props) => <></>} />
							<Route path="/login" render={(props) => <></>} />
							<Route path="/" exact render={(props) => <></>} />

							<Route path="/browse/:letter"
								render={(props) => <Browse {...props} login={this.login} updateRT={this.updateRT} />} />
							<Route path="/categor(y)?(ies)?/:name"
								render={(props) => <Category {...props} login={this.login} updateRT={this.updateRT} />} />
							<Route path="/person/:id"
								render={(props) => <Person {...props} login={this.login} updateRT={this.updateRT} />} />
							<Route path="/:id([0-9]+)/:name"
								render={(props) => <Person {...props} login={this.login} updateRT={this.updateRT} />} />
							<Route path="/org(anization)?/:id"
								render={(props) => <Organization {...props} login={this.login} updateRT={this.updateRT} />} />
							<Route path="/l(oc)?(ation)?/:id/:flr?/:floc?"
								render={(props) => <Location {...props} login={this.login} updateRT={this.updateRT} />} />
							<Route path="/parking"
								render={(props) => <Parking {...props} login={this.login} updateRT={this.updateRT} />} />
							<Route path="/content/:id"
								render={(props) => <Content {...props} login={this.login} updateRT={this.updateRT} />} />

							<Route path="/emergency-contacts" component={PageEmergency} />
							<Route path="/housing-contacts" component={PageHousing} />
							<Route path="/namecoach-update" component={NameCoachUpdate} />

							<Route path="/error/:code?"
								render={(props) => <Error {...props} />} />
							<Route path="/search/:query"
								render={(props) => <SearchResults {...props} login={this.login} isSEO={0} updateRT={this.updateRT} />} />
							<Route path="/:id([0-9]+)"
								render={(props) => <Person {...props} login={this.login} updateRT={this.updateRT} />} />
							<Route path="/:query"
								render={(props) => <SearchResults {...props} login={this.login} isSEO={1} updateRT={this.updateRT} />} />
							<Route render={(props) => <Error {...props} message={window.location.path} />} />
						</Switch>

					</div>
				</Router>
				<Footer />
			</>
		)
	}


}

window.onerror = (e, s) => {
	console.log(e)
	console.log(s)
	document.getElementById('appBody').innerHTML = '<div class="alert alert-danger"><h3>Something Went Wrong</h3><p>We\'re sorry, an error was enountered and your request could not be completed.</p></div></div>';
}




export default App;
