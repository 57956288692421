import React, { Component } from 'react';
import GoogleMap from '../utils/mapComponents/GoogleMap.js';
import MapWelcome from '../utils/mapComponents/MapWelcome.js';

class MapLanding extends Component {
	constructor(props, context) {
        super(props, context);
        this.state = {
            loginResp: props.loginResp,
            wat: props.wat,
        };
      }

     
    render() {
        return (
            <div>      
                <div id="mapJumbotron" className="jumbotron block has-dim-2 text-white text-center">
                    <div className="container">
                        <div className="h1 text-sans-light m-lg-5 mapwelcome">Welcome to Santa Clara.</div>
                    </div>
                </div>
                <div className="Location container py-3 align-items-center justify-content-center justify-content-lg-between">
                
                    <div className="row">
                        <div className="col-md-8 mapRight order-md-12">                  
                             <GoogleMap />
                        </div>
                        <div className="col-md-4 mapLeft order-md-1"> 
                             <MapWelcome />
                        </div>
                    </div>

                </div>
            </div>         
        );
    
    }
}

 export default MapLanding;
 
 