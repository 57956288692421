import React from 'react';
import SEOLink from './../../utils/SEOLink.js';
import './../../../style/Person.css';
import Icon from './../../utils/Icon.js';
import IfLogin from './../../utils/IfLogin.js';
import Position from './../../utils/Position.js';
 
class ListPeople extends React.Component {
	constructor(props, context) {
	  super(props, context);
  	 
	  this.state = {
          people: props.people
	  };
	}
   
	render() {

        return (
            <div>
            {this.state.people.length > 0 &&	    
                <h4 className="text-sans-light" ref="people">People</h4>
                }
                <div className="m-3">
                    {this.state.people.map((psn, index) => {
                      	return <div key={psn.person_id}>
                          <div className="row h-100 p-3 card-body mb-1 bg-light">
                              <div className="col-md-5">
                                  <div className="h5 text-sans-light"><SEOLink type="person" id={psn.person_id} name={psn.last_name+', '+psn.first_name} /></div>
                              
                                  <div className="small">
                                      <Icon name="envelope" sr="Email" />
                                      <IfLogin title="email" value={psn.email}  onLogin={this.props.login}  />
                                  </div>
                              </div>
                              <div className="col-md-7">
                                  {psn.positions.map((pos, index) => {
                                      return <Position key={index} pos={pos} login={this.props.login} layout="browse" addSpacer={index>0}/>
                                  })}									
                              </div>
                          </div>
                      </div>
                    })}					
                    
                </div>
            </div>    
        );
		
	}
  }

  export default ListPeople;
