import React from 'react';
import Icon from './../utils/Icon.js';


class ExternalLink extends React.Component {
	constructor(props, context) {
	  super(props, context);
  	 
	  this.state = {
        url: props.url,
        text: props.text,
        className: props.className,
        includeIcon: props.includeIcon || true
      };
	}
   
 	
	render() {
        if ( !this.state.url  ) {
            return this.props.text;            
        }

        var fullLink = '';
        if ( this.state.url.substr(0,4) === 'http' ) {
            fullLink = this.state.url;
        }  else if ( this.state.url.substr(0,1) === '/' ) {
            fullLink = 'https://www.scu.edu'+this.state.url;
        } else {
            fullLink = 'https://'+this.state.url;
        }
        
       
        return (
            <a href={fullLink} className={this.state.className}>
                {this.state.text ? this.state.text : this.state.url}&nbsp;
                {this.state.includeIcon? <Icon name="external-link-alt" /> :''}
            </a>
        );
		
	}
  }

  export default ExternalLink;
