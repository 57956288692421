import React, { Component } from 'react';
import {config} from './../../config.js';
import Position from './../utils/Position.js';
import Error from './../utils/Error.js';
import Loading from './../utils/Loading.js';
import './../../style/Print.css';
import { Link } from "react-router-dom";

	
class Print extends Component {
		constructor(props) {
			super(props);      
			
			this.state = { orgs: null,			
				error: null,
				obDetails: props.prefetchedData || {} ,
				loginResp: props.loginResp
			} ;       
			if ( props && props.match && props.match.params && props.match.params.orgs ) {
				this.state.orgs = props.match.params.orgs;
			}
		}
 
		componentDidMount() {
			//if this was a single search result or SEO, the results will have been pre-fetched. Only look them up using jsonp if this is not the case
			if ( !this.state.obDetails.person_id && this.state.loginResp.stat === 1) {
				const jsonp = require('jsonp');

				jsonp(config.wsServer +'/apps/ws/phonebook/public/print/'+this.state.orgs, null, (err, data) => {
					if (err) {
						this.setState({  error:err.message});
					} else {
						if ( data.title ==='ok') {
							this.setState({obDetails: data.results, error: null});               
						} else {
							this.setState({  error: data.results});               
						}

					}
				});
			 
			}
		}

	

		render() {

			if ( this.state.loginResp.stat !== 1 ) {
				return <div className="alert alert-warning"><h3>Login Required</h3><p>You must be logged in to print pages of the SCU phonebook. <Link to='/login' onClick={this.props.login}>Click Here to Login</Link></p></div>	
			} else if (  this.state.error != null) {
				return (  <Error message={this.state.error} />  );
			
			} else if ( typeof this.state.obDetails.people == 'undefined' ) {
					return ( <Loading />  );
			} else {
				document.title = 'Print - ' + config.pageTitleBase;           
				return (
					<div className="Print py-3">
						<div className="no-print alert alert-warning">
							<h3>Printable Page</h3>
							<h5>Please consider the environment before printing out the phonebook. The phonebook is constantly changing, so a printed copy will quickly be out of date</h5>
							<p>If you are using a modern browser then this page will format into 3-columns for printing. The page header and this message will not print.</p>
						</div>
						<div className="threeColWrap">
							<h6>Organizations</h6>
							<div className="m-3">
								{this.state.obDetails.orgs.map((org, index) => {
									return <div key={org.org_id+index}>
											<div className="row">
												<div className="col-sm-12">
													<strong>{org.orgRealName? org.orgRealName: org.name}</strong>
													<div className="float-right">
														{org.orgHRphone? <> {org.orgHRphone} </>:<></>}
													</div>
													<div className="clearfix"></div>
													<div className="pl-2">
														{org.people && org.people.length ? org.people.map((psn, index) => {
																return <div>
																		<div className="float-right">{psn.email}</div>
																		<div>{psn.last_name}, {psn.first_name}</div>
																		<div className="pl-2">
																			{psn.positions.map((pos, index) => {
																				return <div className="orgGrpPos">
																					
																					<div key={pos.position_id} className="float-right">
																						{pos.posphone}
																					</div>
																					<div>{pos.title}</div>
																					{index>0?<div className="clearfix"></div>:''}
																					</div>
																			})}		
																		</div>
																	</div>
															}) :''}									
													</div>	
												</div>	
											</div>
										</div>
								})}					
								
							</div>

							<h6>People</h6>
							<div className="m-3">
								{this.state.obDetails.people.map((psn, index) => {
									return <div key={psn.person_id}>
											<div className="row">
												<div className="col-sm-12">
													 
													<div><strong>{psn.last_name}, {psn.first_name}</strong> - {psn.email}</div>
													<div className="pl-2 ">
														{psn.positions.map((pos, index) => {
															return <Position key={index} pos={pos} login={this.props.login} layout="print" addSpacer={index>0}/>
														})}									
													</div>
												</div>
												
											</div>
										</div>
								})}									

							</div>
							

						</div>
						
					</div>
				)			
			}		 
		}
	}


	
		 

export default Print;