
import React from 'react';

function Loading() {
    return (
        <div className="Loading text-center muted">
            <h4>Loading... <br/><i className="fa fa-spin fa-spinner"></i></h4>
        </div>
    );

}

export default Loading;


