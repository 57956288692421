import React, { Component } from 'react';
import {config} from './../../config.js';
import Error from './../utils/Error.js';
import Loading from './../utils/Loading.js';
import './../../style/Person.css';

import Summary from './browse/Summary.js';
import ListOrgs from './browse/ListOrgs.js';
import ListPeople from './browse/ListPeople.js';
import ListLocations from './browse/ListLocations.js';

	
class Browse extends Component {
		constructor(props) {
			super(props);      
			
			this.state = { letter: null,			
				error: null,
				obDetails: props.prefetchedData || {} 
			} ;       
			if ( props && props.match && props.match.params && props.match.params.letter ) {
				this.state.letter = props.match.params.letter;
			}
		}
 
		componentDidMount() {
			//if this was a single search result or SEO, the results will have been pre-fetched. Only look them up using jsonp if this is not the case
			if ( !this.state.obDetails.person_id ) {
				const jsonp = require('jsonp');

				jsonp(config.wsServer +'/apps/ws/phonebook/public/browse/'+this.state.letter, null, (err, data) => {
					if (err) {
						this.setState({  error:err.message});
					} else {
						if ( data.title ==='ok') {
							this.setState({obDetails: data.results, error: null});               
						} else {
							this.setState({  error: data.results});               
						}

					}
				});
				this.props.updateRT('person');
			}

		}

	

		render() {
			

			if (  this.state.error != null) {
				return (  <Error message={this.state.error} />  );
			} else if ( typeof this.state.letter == 'undefined' ) {
				return (
					<div className="Browse">
						Error: no letter specified
					</div>          
				);
			} else if ( typeof this.state.obDetails.people == 'undefined' ) {
					return ( <Loading />  );
			} else {
				document.title =  'Browse ' + this.state.letter.toUpperCase() + ' - ' + config.pageTitleBase;            
				return (
					<div className="Browse container py-3 align-items-center justify-content-center justify-content-lg-between">
						<h1 id="page-title" className="text-sans-light">Browse: {this.state.letter}</h1>
						<Summary orgCt={this.state.obDetails.orgs.length} psnCt={this.state.obDetails.people.length} locCt={this.state.obDetails.locs.length} />
						{config.APP === 'map'?
								<>
									<ListLocations locs={this.state.obDetails.locs} />
									<ListOrgs orgs={this.state.obDetails.orgs} />
									<ListPeople people={this.state.obDetails.people} />
								</>
							:
								<>
									<ListOrgs orgs={this.state.obDetails.orgs} />
									<ListPeople people={this.state.obDetails.people} />
									<ListLocations locs={this.state.obDetails.locs} />
								</>
						}
						
						 
					 </div>
					 
				)			
			}		 
		}
	}


	
		 

export default Browse;