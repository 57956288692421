import React, { Component } from 'react';
import {config} from '../../config.js';
import Error from '../utils/Error.js';
import Loading from '../utils/Loading.js';
import './../../style/Person.css';
import GoogleMap from '../utils/mapComponents/GoogleMap.js';
import Permit from './parking/Permit.js';

	
class Parking extends Component {
		constructor(props) {
			super(props);      
			this.choosePermit = this.choosePermit.bind(this);
			this.state = { name: null,			
				error: null,
				obDetails: props.prefetchedData || {} ,
				chosenPermit :null
			} ;       
			if ( props && props.match && props.match.params && props.match.params.name ) {
				this.state.name = props.match.params.name;
			}
		}
 
		componentDidMount() {

			if ( !this.state.obDetails.length ) {
				const jsonp = require('jsonp');

				jsonp(config.wsServer +'/apps/ws/phonebook/public/parking', null, (err, data) => {
					if (err) {
						this.setState({  error:err.message});
					} else {
						if ( data.title ==='ok') {
							this.setState({obDetails: data.results, error: null});               
						} else {
							this.setState({  error: data.results});               
						}

					}
				});
				this.props.updateRT('person');
			}
		}

		choosePermit(pid) {
			console.log('set active permit ' +pid)
			this.setState({chosenPermit: pid });
		}

		render() {

			
			if (  this.state.error != null) {
				return (  <Error message={this.state.error} />  );
			} else if ( typeof this.state.name == 'undefined' ) {
				return (
					<div className="Parking">
						Error: no category specified
					</div>          
				);
			} else if ( typeof this.state.obDetails.permits === 'undefined' ) {
					return ( <Loading />  );
			} else {
				
				document.title = 'Parking - ' + config.pageTitleBase;            
				var shownMarkers = getVisiblePermitLocs(this.state.obDetails, this.state.chosenPermit) ;
				console.log(shownMarkers)
if (shownMarkers ) {
	
	console.log('YUP')
			}
				return (
				<div className="container py-3 container-map">
					<div className="Parking row">
						<div className="col-md-4 mapLeft"> 
							<a href="#descr" className="text-warning h5 float-right mt-2 ml-3 d-lg-none" data-toggle="collapse"><i className="fas fa-info-circle" aria-hidden="true"></i><span className="sr-only">Description</span></a>
							<h1 id="page-title" className="text-sans-light">Parking</h1>
							 
							<div id="descr" className="collapse p-3 bg-light d-lg-block" dangerouslySetInnerHTML={createMarkup(this.state.obDetails.category.description)}></div>
							
  
							<div className="collapsed hidden h5 visible-xs" data-toggle="collapse" data-target="#permitLocations" aria-expanded="false" aria-controls="permitLocations">
							    <i className="float-right d-md-none fa" aria-hidden="true"></i>
							    Parking Permit Locations
							</div>
							<hr />
							<div className="collapse menu-collapse-sm mb-3" id="permitLocations">
							    <ul className="list-group">
									{this.state.obDetails.permits.map((permit ) => {
										return <Permit key={permit.pid} permit={permit} activePermit={this.state.chosenPermit} onPermitClick={this.choosePermit} />
									})}						
								</ul>
							</div>	
						 
						</div>
						<div className="col-md-8 mapRight">
							{shownMarkers !== null?
								<GoogleMap markers={this.state.obDetails.permits[shownMarkers].loc_ids} key={this.state.chosenPermit}/>
								: 
								<GoogleMap  /> 
							}
								
						</div>
							
					 </div>
				</div> 
				)			
			}		 
		}
	}

	function getVisiblePermitLocs( arr, pid) {
	
		if ( !arr || !arr.permits || !pid ) {
			return null;
		}
		for (var i= 0; i< arr.permits.length; i++ ) {
			if ( arr.permits[i].pid === pid) {
				return i;
			}

		}
		return null;
	}
	function createMarkup(htm) {
		return {__html: htm};
	}	

export default Parking;