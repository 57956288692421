import React from 'react';
import {Image,  Transformation, CloudinaryContext} from 'cloudinary-react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


class ImageSlideshow extends React.Component {
	constructor(props, context) {
        super(props, context);
         
        this.state = {
            images: props.images
        };
      }
    
       
      render() {
  
         if ( ! this.state.images || this.state.images.length === 0 ) {
            return <></> 
         } else {
            return (
                
          

                <div className="gallery">
                    
                    <Carousel autoPlay showThumbs={false}>

                            {
                                this.state.images.map(data => {
                                    return (
                                        <div className="responsive" key={data.public_id}>
                                            <img src={data.url} width="500" dpr="auto" responsive_placeholder="blank" />
                                            
                                             
                                        </div>
                                    )
                                })
                            }
                    </Carousel>


                      
                    <div className="clearfix"></div>

     
                </div>
        


    
            
            );

         }
          
      }
  }

  export default ImageSlideshow;
