import React from 'react';
import {config} from './../../../config.js';
import './../../../style/FloorMap.css';

class FloorMap extends React.Component {
  constructor(props) {
    super(props);
     
    this.state = props.data;
  }
    
  render() {
        if ( !this.state.image_path ) {
            return <></>
        }

      
        var inlinePinStyle ={};
        if ( this.state.room && this.state.imwidth && this.state.room.loc_x  && this.state.room.loc_x !== '' && this.state.room.loc_x !== '0') {


           inlinePinStyle.left =  Math.round((this.state.room.loc_x/this.state.imwidth)*100) +'%';
           inlinePinStyle.top = Math.round((this.state.room.loc_y/this.state.imheight)*100) +'%';
          
        }

        return (
          <div>
            <h3>{this.state.flrHR} {this.state.room && this.state.room.roomnnpmm? ' - '+this.state.room.roomnum:''}</h3>
              <div className="FloorMap">       
                  <div className="fmContainer">
                      <img src={config.imageRoot+ this.state.image_path} alt="Location Marker" className="floorImg"/>                       
                        {this.state.room && inlinePinStyle.left   ?
                            <div className="pinContainer" style={inlinePinStyle}>
                                <div className="pinContainerInner">
                                    <img src={"/apps/phonebook/assets/marker.png"} alt="Specific Location marker" />    
                                </div>
                            </div>                 
                        : <></> } 
                  </div>     
              </div>
          </div>
        );

    }
  }

  export default FloorMap;
