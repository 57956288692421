import React from 'react';
import './../../style/OrgHierarchy.css'
import SEOLink from './SEOLink.js'


class OrgHierarchy extends React.Component {
	constructor(props, context) {
	  super(props, context);
  	 
	  this.state = {
          orgData: props.org
	  };
	}
 
	render() {

       if ( this.state.orgData.hier.length > 0 && this.state.orgData.hier[0].children.length > 0 ) {
            return (
                <div className="OrgHierarchy mt-5">
                    <h6>Other Organizations in {this.state.orgData.name}</h6>
                    <ul>
                        
                        {this.state.orgData.hier[0].children.map((child,index) =>(
                            <li key={index}><SEOLink type="org" id={child.org.org_id} name={child.org.name} /></li>

                        ))}

                        
                    </ul>
                </div>
            );
        } else {

            return <></>
        }
        
	}
  }

  export default OrgHierarchy;
