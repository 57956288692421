import React, { Component } from "react";
import { config } from "./../../config.js";
import Loading from "./../utils/Loading.js";
import Position from "./../utils/Position.js";
import "./../../style/Organization.css";
import ExternalLink from "./../utils/ExternalLink.js";
import GoogleMap from "./../utils/mapComponents/GoogleMap.js";
import OrgHierarchy from "./../utils/OrgHierarchy.js";
import OrgBreadcrumb from "./../utils/OrgBreadcrumb.js";
import IfLogin from "./../utils/IfLogin.js";
import { Link } from "react-router-dom";
import SuggestChanges from "../utils/SuggestChanges.js";
import Icon from "./../utils/Icon.js";
import { CSVLink } from "react-csv";

class Organization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      pathname: props.pathname,
      obDetails: props.prefetchedData || {},
      orgContactToDownload: [
        ["Raed", "Labes", "rl@smthing.co.com"],
        ["Yezzi", "Min l3b", "ymin@cocococo.com"],
      ],
    };
    this.csvLinkRef = React.createRef(); // ref to the link to download csv data
    if (props && props.match && props.match.params && props.match.params.id) {
      this.state.id = props.match.params.id;
    }
  }

  /**
   * handle the event when the "Export this Orgnization" button is clicked
   * send jsonp request to server to fetch the contact data
   * filter the data and export it through react-csv
   */
  handleExportOrgBtnClicked = () => {
    if (!this.state.obDetails.person_id) {
      const jsonp = require("jsonp");
      jsonp(
        config.wsServer +
          "/apps/ws/phonebook/public/print/" +
          this.state.obDetails.org_id,
        null,
        (err, data) => {
          console.log("returned data", data);
          if (err) {
            this.setState({ error: err.message });
          } else {
            if (data.title === "ok") {
              const contactToDownload = data.results.people.map((person) => {
                return [
                  person.first_name,
                  person.last_name,
                  person.pertitle,
                  person.email,
                  person.positions[0].posphone,
                  person.positions[0].title,
                  person.positions[0].orgname,
                  person.positions[0].locstring,
                  person.positions[1] ? person.positions[1].title : null,
                  person.positions[1] ? person.positions[1].orgname : null,
                  person.positions[2] ? person.positions[2].locstring : null,
                ];
              });
              const csvData = [
                [
                  "First Name",
                  "Last Name",
                  "Person Title",
                  "Email",
                  "Phone",
                  "Title",
                  "Organization",
                  "Office Location",
                  "Title 2",
                  "Organization 2",
                  "Office Location 2",
                ],
                ...contactToDownload,
              ];

              console.log("csv data", csvData);
              this.setState({ orgContactToDownload: csvData }, () => {
                setTimeout(() => {
                  this.csvLinkRef.current.link.click();
                });
              });
            } else {
              if (data.results.includes("You must be logged in")) {
                window.alert(
                  'You must be logged in to print the SCU Phonebook. Scroll to the top of the page and click "Switch to SCU-only view".'
                );
              }
            }
          }
        }
      );
    }
  };

  componentDidMount() {
    //if this was a single search result or SEO, the results will have been pre-fetched. Only look them up using jsonp if this is not the case
    if (!this.state.obDetails.org_id) {
      const jsonp = require("jsonp");

      jsonp(
        config.wsServer + "/apps/ws/phonebook/public/org/" + this.state.id,
        null,
        (err, data) => {
          if (err) {
            this.setState({ error: err.message });
          } else {
            if (data.title === "ok") {
              this.setState({ obDetails: data.results.org, error: null });
            } else {
              this.setState({ error: data.results });
            }
          }
        }
      );
      //this.props.updateRT('org');
    }
  }

  render() {
    if (typeof this.state.id == "undefined") {
      return (
        <div className="Organization alert alert-warning">
          Error: no organization specified
        </div>
      );
    } else if (this.state.obDetails === "Not Found") {
      return (
        <div className="Organization alert alert-warning">
          Error: The organization was not found.
        </div>
      );
    } else if (typeof this.state.obDetails.org_id == "undefined") {
      return <Loading />;
    } else {
      var org = this.state.obDetails;
      document.title = org.name + " - " + config.pageTitleBase;

      return (
        <div className="Organization container py-3 align-items-center justify-content-center justify-content-lg-between">
          <OrgBreadcrumb bc={org.breadcrumb} />

          <h3 className="h2 text-sans-light">{org.name}</h3>
          <div className="lead">
            {org.orgHRphone ? <div className="lead">{org.orgHRphone}</div> : ""}
          </div>
          <hr />
          <div className={org.positions.length > 0 ? "row" : " pl-5 pr-5"}>
            <div className="grid-item mb-3cd grid-01 col-md-8 col-sm-8">
              {org.positions.length === 0 ? (
                <div>This organization does not have any positions.</div>
              ) : (
                ""
              )}
              {org.positions.map((pos, index) => {
                return <Position key={index} pos={pos} layout="org" />;
              })}
            </div>

            <div
              className={
                "mb-3 " +
                (org.positions.length > 0
                  ? " grid-01 grid-item col-md-4 col-sm-4"
                  : "")
              }
            >
              <div className="">
                <Icon name="map" sr="Location" />{" "}
                <IfLogin
                  value={org.locstring}
                  onLogin={this.props.login}
                  locLink={org}
                  title="location"
                />
              </div>
              {config.APP === "map" && org.lat ? (
                <GoogleMap
                  markerlat={org.lat}
                  markerlng={org.lng}
                  mapHeight={200}
                />
              ) : (
                ""
              )}
              <div className="muted">
                {org.office_hours ? "Office Hours: " + org.office_hours : ""}
              </div>
              {org.sites.length > 0 ? (
                <div className="">
                  <ExternalLink
                    className="btn btn-sm btn-light"
                    url={org.sites[0].site_url}
                    text="website"
                  />
                </div>
              ) : (
                ""
              )}

              {org.descr_full.trim() !== "" ? (
                <div>
                  <hr />
                  <strong>About</strong>
                  <br />
                  {org.descr_full}
                </div>
              ) : (
                ""
              )}
              <ul className="list-group text-left small">
                {org.gennums.map((gnum, index) => {
                  return (
                    <li className="list-group-item" key={index}>
                      {gnum.siteurl ? (
                        <ExternalLink url={gnum.siteurl} text={gnum.title} />
                      ) : (
                        <strong>{gnum.title}</strong>
                      )}{" "}
                      <br />
                      {gnum.description ? (
                        <div className="muted">{gnum.description}</div>
                      ) : (
                        ""
                      )}
                      {gnum.phone ? <div>{gnum.phone}</div> : ""}
                      {gnum.email ? (
                        <div>
                          Email: <a href={gnum.email}>{gnum.email}</a>
                        </div>
                      ) : (
                        ""
                      )}
                      {gnum.fax ? <div>Fax: {gnum.fax}</div> : ""}
                    </li>
                  );
                })}
              </ul>

              {org.sites.length > 1 ? (
                <div>
                  <ul className="list-group mt-3 text-left small">
                    {org.sites.map((site, index) => {
                      return (
                        <li className="list-group-item" key={index}>
                          <ExternalLink url={site.site_url} />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
              <OrgHierarchy org={org} />
              <Link
                to={"/print/" + org.org_id}
                className="btn btn-secondary text-white mt-3"
              >
                Print this Organization
              </Link>
              <button
                onClick={this.handleExportOrgBtnClicked}
                className="btn btn-secondary text-white mt-3"
              >
                Export this Organization
              </button>

              <CSVLink
                data={this.state.orgContactToDownload}
                ref={this.csvLinkRef}
                filename="contacts.csv"
              />
            </div>
          </div>
          {org.hasEditAccess === "org" ? (
            <div className="alert alert-secondary mt-3" role="alert">
              <strong>Updates needed?</strong>
              <br />
              You have permission to{" "}
              <a href={"/apps/phonebook/moderator/?org=" + org.org_id}>
                edit this organization
              </a>{" "}
              in the phonebook moderator application.
            </div>
          ) : (
            <SuggestChanges
              auth={org.isAuthenticated}
              type="organization"
              id={org.org_id}
              hr={"Organization: " + org.name}
            />
          )}
        </div>
      );
    }
  }
}

export default Organization;
