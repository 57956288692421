import React, { useState, useEffect } from 'react';
import axios from 'axios';

let pageCountUtil = 0;
let intervalIdUtil = null;

function NameCoachUpdate() {
  const [updateResult, setUpdateResult] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [intervalId, setIntervalID] = useState(null);
  pageCountUtil = pageCount
  intervalIdUtil = intervalId;


  const handleBulkUpdateBtnClicked = () => {
    const requestInterval = setInterval(() => updateNameCoachOnePage(), 3000);
    setIntervalID(requestInterval);
  }

  const handleLookbackUpdateBtnClicked = () => {
    var bodyFormData = new FormData();
    bodyFormData.append('action', 'bulk-update-by-date');
    axios.get('/apps/phonebook/integrations/namecoach/', {
      params: {
        action: 'bulk-update-by-date',
      }
    })
      .then((res) => {
        const updatedPersonCount = res.data.updatedPersonCount;
        const updateVerbo = `${updatedPersonCount} records have been updated`;
        setUpdateResult((prevValue) => [...prevValue, updateVerbo]);
      })
  }

  /**
   * Request one page of data from namecoach api, then use the data to update namecoach url in the phonebook database
   * The request to the namecoach api + update the phonebook database are handled on the server
   * this function just provide the action type and page number to the server 
   * also receive the update result and display them on the front end
   * 
   */
  const updateNameCoachOnePage = () => {
    console.log(`Sending request for page ${pageCountUtil} `);
    var bodyFormData = new FormData();
    bodyFormData.append('action', 'bulk-update-by-page');
    bodyFormData.append('page', pageCountUtil);
    axios.get('/apps/phonebook/integrations/namecoach/', {
      params: {
        action: 'bulk-update-by-page',
        page: pageCountUtil
      }
    })
      .then((res) => {
        const page = res.data.page;
        const isPageEnd = res.data.isEnd;
        // console.log(`page ${page} has been updated current state of pageCount is ${pageCount}`);
        var updatedPersonCount = 0
        if (!isPageEnd && page < 2) {
          updatedPersonCount = res.data.updatedPersonCount;
          const updateVerbo = `Page ${page} completed. ${updatedPersonCount} records have been updated`;
          setUpdateResult((prevValue) => [...prevValue, updateVerbo]);
        } else {
          clearInterval(intervalIdUtil);
          const updateVerbo = `No more data from page ${page}. Bulk data finished`;
          setUpdateResult((prevValue) => [...prevValue, updateVerbo]);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
      })
    setPageCount(prevVal => prevVal + 1);
  }

  return (
    <div className='container'>
      <button onClick={handleBulkUpdateBtnClicked}>Bulk Update</button>
      <button onClick={handleLookbackUpdateBtnClicked}>Sync Last 20 days</button>
      <ul>
        {updateResult.map((result) => {
          return <li>{result}</li>
        })}
      </ul>

    </div>
  )
}

export default NameCoachUpdate