import React from 'react';
import SEOLink from './../../utils/SEOLink.js';
import {config} from './../../../config.js';
import ImageSlideshow from './ImageSlideshow.js';


class LeftCol extends React.Component {
	constructor(props, context) {
        super(props, context);
         
        this.state = {
            loc: props.location,
            gallery: []
        };
      }
    
       
      render() {
  
         if ( this.state.loc.floors || this.state.loc.floor ) {
            return (
                <div>
                    <h3 className="text-sans-light">{this.state.loc.name}</h3>
                    { this.state.loc.build_num && this.state.loc.build_num !== this.state.loc.name? <p className="h5">Building: {this.state.loc.build_num}</p>:''}
                </div> 
            );

         } else {
            return (
                <div>
                    
                    
                    <h1 className="text-sans-light">{this.state.loc.name}</h1>
                    
                    <div className="mb-2 border-top pt-2">
                    {  this.state.loc.all_floors.length > 0 ?
						<a className="btn btn-primary float-right" href={config.basePath+'/location/'+this.state.loc.loc_id+'/floors'}>View Floor Maps <i className="fad fa-map" aria-hidden="true"><span className="sr-only">Map icon</span></i></a>
                        :''}
                    {  this.state.loc.descr_full.length > 0 ?
                        <a href="#descr" className="text-warning h5 mt-2 mr-2 d-lg-none" data-toggle="collapse"><i className="fas fa-info-circle" aria-hidden="true"></i><span className="sr-only">Description</span></a>
                        :''}
                    { this.state.loc.build_num && this.state.loc.build_num !== this.state.loc.name? <span>Building: {this.state.loc.build_num}  </span>:''}

                    </div>

                    {  this.state.loc.descr_full.length > 0 ?
                        <p id="descr" className="collapse p-3 bg-light d-lg-block" dangerouslySetInnerHTML={createMarkup(this.state.loc.descr_full)} />
                        
                        :''}
                    
                    <ImageSlideshow images={this.state.loc.images} />

    
                    {this.state.loc.orgs.length>0?
                    <div className="collapsed hidden h5 visible-xs border-top pt-3" data-toggle="collapse" data-target="#orgLocations" aria-expanded="false" aria-controls="permitLocations">
                        <i className="float-right d-md-none fa" aria-hidden="true"></i>
                        Organizations at this location
                    </div> :''}
                    <div className="collapse menu-collapse-sm mb-3" id="orgLocations">
                    <ul className="list-group"	>							
                        {this.state.loc.orgs.map(  (org,index) =>  
                            <li className="list-group-item list-group-item-light" key={org.org_id}>
                                    <div className="float-right">{org.orgHRphone}</div>
                                    <SEOLink type="org" id={org.org_id} name={org.name} />
                            </li>
                        )}
                    </ul>
                    </div>
                </div> 
            );

         }
          
      }
  }

  function createMarkup(htm) {
        return {__html: htm};
    }

  export default LeftCol;
