import React, { Component } from 'react';

	
class SocialIcon extends Component {
		constructor(props) {
			super(props);      
			this.state = { 
				type: props.type,
				icon: props.icon || props.type,
                link: props.link || ''
             } ;       
		}
 
		 
		render() {              
            var linkbase;
            switch(this.state.type) {
                case 'facebook':  linkbase = 'https://www.facebook.com/';     break;
                case 'twitter':  linkbase = 'https://twitter.com/';     break;
                case 'linkedin':  linkbase = 'https://www.linkedin.com/';     break;
                case 'youtube':  linkbase = 'https://www.youtube.com/';     break;
                case 'instagram':  linkbase = 'https://instagram.com/';     break;
                default: linkbase = ''; break;
            }

			if (this.state.link)  {
                return (
                    <span className="SocialIcon">
                        
                        <a className={this.state.type} href={ ( this.state.link.substr(0,4) === 'http'? '':linkbase)+this.state.link}>
                        <i className={'demo-icon icon-'+this.state.icon+''}></i>
                            <span className="sr-only">{this.state.type} Social</span>
                        </a>                                                
                    </span> 
                    
                );

            } else {
                return <></>;

            }
		
		 
		}
	}

	
export default SocialIcon;