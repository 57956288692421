import React from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import './../../style/react-autocomplete.css';  
import {config} from './../../config.js';


window.ac_values = [ ];
	

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
	const inputValue = value.trim().toLowerCase();
	const inputLength = inputValue.length;

	return inputLength < 3 ? [] : window.ac_values.filter(lang =>
		lang.name.toLowerCase().slice(0, inputLength) === inputValue
	);
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
	<div>
		{suggestion.name}
	</div>
);

class MyAutoComplete extends React.Component {
	constructor(props) {
		super();

		// Autosuggest is a controlled component.
		// This means that you need to provide an input value
		// and an onChange handler that updates this value (see below).
		// Suggestions also need to be provided to the Autosuggest,
		// and they are initially empty because the Autosuggest is closed.
		this.state = {
			value:  props.searchValue || '',
			suggestions: [],
			placeholder: props.placeholder || 'Search'
		};
		this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
		this.inputChanged = this.inputChanged.bind(this);
	}
	componentDidMount() {
		if ( this.state.suggestions.length === 0 ) {
			//ignore errors to this ajax request, since that will just mean the autocomplete won't work
			axios.get(config.wsServer+'/apps/ws/phonebook/public/autocomplete')
				.then( resp => {
					if ( resp.data.title ==='ok') {
						//this.setState({ac_values: resp.data.results});
						window.ac_values =  resp.data.results;
					}
				})
			.catch(console.error);

		}
		
	}  

	// Autosuggest will call this function every time you need to update suggestions.
	// You already implemented this logic above, so just use it.
	onSuggestionsFetchRequested = ({ value }) => {
		this.setState({
			suggestions: getSuggestions(value)
		});
	};

	// Autosuggest will call this function every time you need to clear suggestions.
	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		});
	};

	inputChanged (e, { newValue }) {
	
				this.props.updateFormData(e.target.value || newValue); 
				this.setState({
						value: e.target.value || newValue
					});
		}


	onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
		this.props.onSelect(suggestion);
	}

	render() {
		const { value, suggestions } = this.state;

		// Autosuggest will pass through all these props to the input.
		const inputProps = {
			placeholder: this.state.placeholder,
			value,
			id: 'pbsearch',
			onChange: this.inputChanged
		};

		// Finally, render it!
		return (
			<Autosuggest
				suggestions={suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested}
				onSuggestionSelected={this.onSuggestionSelected}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				inputProps={inputProps}
			/>
		);
	}
}

export default MyAutoComplete;