import React, { Component } from 'react';
import IfLogin from './../utils/IfLogin.js';
import Icon from './../utils/Icon.js';
import SEOLink from './SEOLink.js'
import { config } from '../../config.js';
	
class Position extends Component {
		constructor(props) {
			super(props);      
			this.state = { 
				pos: props.pos,
				layout: props.layout || 'psn',
				returnTo: this.props.returnTo || '' 
			} ;       
		}
		 
		render() {              
			const  position = this.state.pos;
			if ( this.props.layout && this.props.layout === "person") {
				return (
					<>
						<div className="card bg-light mb-3">
							
							<div className="card-body">
							    <h4 className="text-sans-light">{position.title}</h4>
								<hr/>
								<h5 className="text-sans-light"><SEOLink type="org" id={position.org_id} name={position.orgname} /></h5>
								<Icon name="phone" sr="Phone" /> <IfLogin title="phone" value={position.posphone}  onLogin={this.props.login}  />	<br/>
								<div className="card-text"><Icon name="map" sr="Location" /> <IfLogin title="location" value={position.locstring} onLogin={this.props.login}  locLink={position}   />  </div>
							
							</div>
						</div>
						{this.props.addSpacer? 
							<div className="w-100 d-none d-sm-block d-md-none"></div>
							:''}
					</>
				)
			} else if ( this.props.layout && this.props.layout === "browse") {
				return (
					<>
						{this.props.addSpacer? <hr/> :''}	
						<div className="text-sans-light">{position.title} - <SEOLink type="org" id={position.org_id} name={position.orgname} /></div>
						{config.APP === 'map' ? 
								<div>
									<Icon name="map" sr="Location" /> 
									<IfLogin value={position.locstring} onLogin={this.props.login} locLink={position} title="location" />
								</div>
								 
							:
							<div><Icon name="phone" sr="Phone" /> <IfLogin title="phone" value={position.posphone}  onLogin={this.props.login}  /></div>
						}
					</>
				)
			
			} else if ( this.props.layout && this.props.layout === "print") {
				return (
					<div className="orgGrpPos">
						<div className="float-right">{position.posphone}</div>
						<div>{position.title} - {position.orgname} </div>
					</div>
				)
			
			} else { //layout = 'org'
				return (
					<div className="Position" key={this.state.key}>
						
						<div className={"row h-100 p-3 card-body mb-1 " + (position.list_first === '1'? 'bg-cream':'bg-light ')}>

							
							<div className="col-md-6">
								<div className="h4 text-sans-light">
									{position.person_id === '' || position.person_id  === null ? <em>Vacant</em>:
										<SEOLink type="person" id={position.person_id} name={position.pertitle +' '+ position.first_name+' '+position.last_name+' '+position.suffix} />
									}
								</div>
								<div className="">{position.title}</div>		
							</div>
							<div className="col-md-6">
								<Icon name="phone" sr="Phone" />
								<IfLogin title="phone" value={position.posphone}  onLogin={this.props.login}  />	<br/>
								<i className="fas fa-envelope text-secondary mr-2"><span className="sr-only">Phone</span></i> 
								<IfLogin title="email" value={position.email}  onLogin={this.props.login}  />	<br/>
								<Icon name="map-marker" sr="Location" />
								<IfLogin title="location" value={position.locstring} onLogin={this.props.login} locLink={position}  />  
							</div>
						</div>
							
						 
						
							

					</div> 
					
				);

			}
		
		 
		}
	}

	
export default Position;