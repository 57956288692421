import React  from 'react';
import {config} from './../../../config.js';


class CategoryName extends React.Component {
	constructor(props, context) {
      super(props, context);
      
      this.menuItemHover = this.menuItemHover.bind(this);
      
	  this.state = {
            loc_id: props.loc_id || null,
            content_id: props.content_id || null,
            lat: props.lat || null,
            lng: props.lng || null,
            title: props.title || null,
            myMarker: null,
            myInfoWindow: null
	  };
	}
  
    menuItemHover() {
        window.closeAllInfoWindows();        

        if ( this.state.lat && this.state.lat !== 0 && this.state.lat !== '0' && ! this.state.myInfoWindow) {
            var ll =  new window.google.maps.LatLng(this.state.lat, this.state.lng);
            var newmarker = new window.google.maps.Marker({
                position: ll,
                map: window.map,
                title: this.state.title
            });
            
            var newIW = new window.google.maps.InfoWindow({
                content: this.state.title
            });
            window.placedMarkers.push( newmarker );
            window.infoWindows.push( newIW );

            this.setState({myMarker: newmarker, myInfoWindow: newIW});

        }
        if ( this.state.myInfoWindow ) {
            this.state.myInfoWindow.open(window.map, this.state.myMarker );

        }
           
    }

	render() {
        var linkDest;
        if ( this.state.loc_id && this.state.loc_id !== '0' ) {
            linkDest = config.basePath+'/location/'+this.state.loc_id;
        } else {
            linkDest = config.basePath+'/content/'+this.state.content_id;
        }    
        return (
            <li className="list-group-item list-group-item-light" key={this.state.loc_id} onMouseEnter={this.menuItemHover}>
                <div className="float-right">
                   
                        <a href={linkDest}><i className="fa fa-arrow-right"></i><span className="sr-only">View Location</span></a>
                        
                </div>
                <a href={linkDest}>{this.state.title}	</a>										
            </li>
        );
		
	}
  }

  export default CategoryName;

  