import React, { Component } from 'react';
import housingNums from './../../static/housing.json';
import {config} from './../../config.js';

class PageHousing extends Component {
	constructor(props, context) {
        super(props, context);
        this.state = {
            loginResp: props.loginResp,
            wat: props.wat,
        };
      }
  
     
    render() {
		document.title = 'Housing Contacts - ' + config.pageTitleBase;            
        return (
            
            <div className="container py-3">                            
              	<h3 className="text-sans-light mb-3">Housing Contacts</h3>
              	<hr/>
              	<div className="row">
                	<div className="col-md-8">
	                     <ul className="list-group">
	                         { housingNums.map( (item,index) =>  
	                             <li className="list-group-item" key={index}>{item.name} <div className="float-right"><a href={"tel:"+item.phone}>{item.phone}</a></div></li>                  
	                         )}                                    
	                     </ul>
                     </div>
                
 
                     </div>
                     </div>
                    
        )
    
    
    }
}

export default PageHousing;
 