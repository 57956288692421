import React from 'react';
import Modal from './Modal.js';
import {config} from './../../config.js';
import axios from 'axios';
import qs from 'qs';

class SuggestChanges extends React.Component {
	constructor(props, context) {
	  super(props, context);
  	  this.state = {
            isAuthenticated: props.auth,
            type: props.type,
            id: props.id,
            humanReadable: props.hr,
            modalVisible: false,
            error:null
        
        }
		this.toggleModal = this.toggleModal.bind(this);
		this.sendSuggestion = this.sendSuggestion.bind(this);
	}
  
    toggleModal(e) {
        this.setState({modalVisible: !this.state.modalVisible});
        if (e && e.preventDefault ) {
            e.preventDefault();
        }
    }

    sendSuggestion(e) {
        
        var params = {suggestions: this.state.suggestion };
        if ( this.state.type === 'location') {
            params.loc_id = this.state.id;
        } else if ( this.state.type === 'organization' ) {
            params.org_id = this.state.id;
        } else if ( this.state.type === 'person') {
            params.person_id = this.state.id;
        }

		const options = {
			method: 'POST',
			headers: { 'content-type': 'application/x-www-form-urlencoded' },
			data:  qs.stringify(params) ,
            url: config.wsServer+'/apps/ws/phonebook/suggest',
            withCredentials: true
		};
		axios(options).then( resp => {
					this.setState({status: resp.data.title, statusMess: resp.data.results||null, loginURL: resp.data.results.loginURL||null});
				}).catch(console.error);;
	 
        if ( e && e.preventDefault) {
            e.preventDefault();
        }
    }

	render() {
        //only allow logged in users to suggest changes
        if ( ! this.state.isAuthenticated ) {
            return <></>
        }

      
        var modalBtns = [{ color:"default", action: this.toggleModal, text: 'cancel'},
           { color:"primary", action: this.sendSuggestion, text: 'Send'            
        }];
        if ( this.state.status === 'ok' ) {
            modalBtns = [{ color:"success", action: this.toggleModal, text: 'Done'}];
        }

        return (
            <div className="alert alert-dark mt-3" role="alert">
               <small>You do not have permission to edit this {this.state.type} but we encourage you to <button onClick={this.toggleModal}>suggest changes</button> if anything is inaccurate, incomplete, or could be improved.</small>
               {this.state.modalVisible}
                <Modal title="Suggest Changes" show={this.state.modalVisible} toggler={this.toggleModal} buttons={modalBtns}>
                    {this.state.status?
                         this.state.loginURL ? 
                            <div className="alert alert-danger" role="alert">Your login has expired. Please <a href={this.state.loginURL}>click here to re-login</a></div>
                            :  this.state.status !== 'ok'? 
                                <div className="alert alert-danger" role="alert">{this.state.statusMess}</div> 
                                :''
                        :''
                        }
                    {this.state.status === 'ok'?
                        <div className="alert alert-success" role="alert">{this.state.statusMess}</div> :
                        <div>
                            Please describe what should be changed, and this will be submitted to the person who has permission to edit <strong> {this.state.humanReadable}</strong>.
                            <textarea className="form-control" style={{ height: '120px' }} value={this.state.suggestion} onChange={e => this.setState({ suggestion: e.target.value })} ></textarea>

                        </div>
                        }
                </Modal>            
            </div>
        );
		
	}
  }

  export default SuggestChanges;
