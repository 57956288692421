import React from 'react';
import {config} from './../../config.js';



class LocationLink extends React.Component {
	constructor(props, context) {
	  super(props, context);
  	 
	  this.state = {
         data:props.data,
         linktext: props.linktext || props.data.locstring,
         className: props.className || null
        };
	}
   
 	
	render() {

        var link = config.basePath+'/l/';

        if ( this.state.data.loc_option === 2 ) { 
            
            return <>{this.state.data.location} </>
        }

        if ( this.state.data.loc_id ) { 
            if ( this.state.data.buildname ) { 
                link += seoString(this.state.data.buildname );
            } else {
                link += this.state.data.loc_id;
            }
        } else {
            return <>{this.state.data.locstring} </>
        }
        var hasFloor = false;
        if ( this.state.data.floornum && this.state.data.floornum !== 0 ) { 
            link += '/'+this.state.data.floornum
            hasFloor=true;
        } else if ( this.state.data.loc_fid && parseInt(this.state.data.loc_fid) !== 0  ) { 
            link += '/'+this.state.data.loc_fid
            hasFloor=true;
        }
      
        if ( this.state.data.roomnum ) { 
            link += '/'+ (!hasFloor?'Room-':'')+ seoString(this.state.data.roomnum);
        } else if (this.state.data.loc_coord ) { 
            link += '/'+  (!hasFloor?'Room-':'')+ this.state.data.loc_coord;
        } else if (this.state.data.loc_room && parseInt(this.state.data.loc_room) !== 0 ) { 
            link += '/'+  (!hasFloor?'Room-':'')+ seoString(this.state.data.loc_room);
        } else if (this.state.data.floc_id && parseInt(this.state.data.floc_id) !== 0 ) { 
            link += '/'+  (!hasFloor?'Room-':'')+ seoString(this.state.data.floc_id);
        }
    
        return (
            <a href={link} className={this.state.className}>
                {this.state.linktext}
            </a>
        );
		
	}
  }

  function seoString (str) {
    return encodeURI(str.trim().replace(/ /g,'-'));
  }
  export default LocationLink;
