import React from 'react';
import {config} from './../../config.js';


class Error extends React.Component {
	constructor(props, context) {
	  super(props, context);
  	 
	  this.state = {
        message: props.message ||'',
        code: (props.match && props.match.params && props.match.params.code)? props.match.params.code : ''
        };
	}
  
    componentDidMount() {
        //do something
    }
 	
	render() {
        console.log(config);
        return (
            <div className="Error">
                <div className="alert alert-danger">
                    <h3>Something Went Wrong</h3>
                    <p>We're sorry, an error was enountered and your request could not be completed.</p> 
                    {this.state.message?<div><strong>Details:</strong> {this.state.message}</div>:''} 
                    {this.state.code && typeof config.error_codes[this.state.code] != 'undefined'?<div><strong>Details:</strong> {config.error_codes[this.state.code]}</div>:''}

                </div>
            </div>
        );
		
	}
  }

  export default Error;
