import React from 'react';

const Footer = ( ) => (
  <footer className="footer-core border-top bg-white pt-4 footer-core">
      <div className="container">
        <a href="https://www.scu.edu/" className="h4 text-dark text-uppercase wordmark d-block mb-4">Santa Clara University</a>
        <div className="row">
          <div className="col-12 col-md-6">
            <p className="mb-0">500 El Camino Real<br />Santa Clara, CA 95053<br />408-554-4000</p>
            <ul className="list-unstyled list-spaced">
            <li><a href="https://www.scu.edu/map/">Maps &amp; Directions</a></li>
            <li><a href="https://phonebook.scu.edu/">Contact Us</a></li>
            </ul>
            <ul className="list-inline mb-0 footer-social-icons">
              <li className="list-inline-item"><a className="facebook" href="https://www.facebook.com/SantaClaraUniversity">
              <i className="demo-icon icon-facebook-squared"></i>
              <span className="sr-only">SCU on Facebook</span>
              </a></li>
              <li className="list-inline-item"><a className="twitter" href="https://twitter.com/SantaClaraUniv">
              <i className="demo-icon icon-twitter"></i>
              <span className="sr-only">SCU on Twitter</span>
              </a></li>
              <li className="list-inline-item"><a className="linkedin" href="https://www.linkedin.com/edu/santa-clara-university-17914">
              <i className="demo-icon icon-linkedin"></i>
              <span className="sr-only">SCU on Linkedin</span>
              </a></li>
              <li className="list-inline-item"><a className="youtube" href="https://www.youtube.com/channel/UCsii-lMGVZ5tL3lj2No6i7A?sub_confirmation=1">
              <i className="demo-icon icon-youtube"></i>
              <span className="sr-only">SCU on YouTube</span>
              </a></li>
              <li className="list-inline-item"><a className="instagram" href="https://instagram.com/santaclarauniversity/">
              <i className="demo-icon icon-instagram"></i>
              <span className="sr-only">SCU on Instagram</span>
              </a></li>
            </ul>
            <hr className="d-block d-md-none pt-2" />
          </div>
          <div className="col-12 col-md-3">
          <ul className="list-unstyled list-spaced">
            <li>
            <div className="h6 text-uppercase">Resources</div>
            </li>
            <li><a href="https://www.scu.edu/emergency/">Emergency Info</a></li>
            <li><a href="https://www.scu.edu/hr/careers/">Careers</a></li>
            <li><a href="https://www.scu.edu/login">Login</a></li>
            <li><span className="fa fa-universal-access text-primary"><span className="sr-only">Accessiblity icon</span></span> <a href="https://www.scu.edu/accessibility/">Accessibility</a></li>
            </ul>
          </div>
          <div className="col-12 col-md-3">
          <ul className="list-unstyled list-spaced">
            <li>
            <div className="h6 text-uppercase ">Our Campus</div>
            </li>
            <li><a href="https://www.scu.edu/missionchurch/">The Mission</a></li>
            <li><a href="https://www.scu.edu/events/">Event Calendar</a></li>
            <li><a href="https://www.scu.edu/news-and-events/">News</a></li>
          </ul>
          </div>
        </div>
      </div>
      <p className="bg-gradient-primary mb-0 mt-4 p-3 small text-center">© 2021 Santa Clara University</p>
    </footer>

)

export default Footer;