import React, { Component } from 'react';
import {config} from '../../config.js';
import Error from '../utils/Error.js';
import Loading from '../utils/Loading.js';
import './../../style/Person.css';
import GoogleMap from '../utils/mapComponents/GoogleMap.js';
import CategoryItem from '../utils/mapComponents/CategoryItem.js';

	
class Category extends Component {
		constructor(props) {
			super(props);      
			
			this.state = { name: null,			
				error: null,
				obDetails: props.prefetchedData || {} 
			} ;       
			if ( props && props.match && props.match.params && props.match.params.name ) {
				this.state.name = props.match.params.name;
			}
		}
 
		componentDidMount() {
			//if this was a single search result or SEO, the results will have been pre-fetched. Only look them up using jsonp if this is not the case
			if ( !this.state.obDetails.person_id ) {
				const jsonp = require('jsonp');

				jsonp(config.wsServer +'/apps/ws/phonebook/public/category/'+this.state.name, null, (err, data) => {
					if (err) {
						this.setState({  error:err.message});
					} else {
						if ( data.title ==='ok') {
							this.setState({obDetails: data.results, error: null});               
						} else {
							this.setState({  error: data.results});               
						}

					}
				});
				this.props.updateRT('person');
			}
		}

	

		render() {
			

			if (  this.state.error != null) {
				return (  <Error message={this.state.error} />  );
			} else if ( typeof this.state.name == 'undefined' ) {
				return (
					<div className="Category">
						Error: no category specified
					</div>          
				);
			} else if ( typeof this.state.obDetails.content == 'undefined' ) {
					return ( <Loading />  );
			} else {

				if ( this.state.obDetails.content.length === 0  ) {
					return (
						<div className="Category container py-3 align-items-center justify-content-center justify-content-lg-between py-2">
							<div class="row">						
								<div className="col-md-4 mapLeft"> 
									<h3>Not Found</h3>
									<hr />
									<p>Error: No content was found in this category.</p>
								</div>
								<div className="col-md-8 mapRight"> 
									<GoogleMap markers={this.state.obDetails.content.content_ids} />
								</div>	
							</div>
						</div>
						);
				}
				 
				document.title =  this.state.obDetails.content.cattitle + ' - ' + config.pageTitleBase;            
				return (
					<div className="Category container py-3 container-map align-items-center justify-content-center justify-content-lg-between py-2">
						<div class="row">
							<div className="col-md-4 mapLeft"> 
							    <a href="#descr" className="text-warning h5 float-right mt-2 ml-3 d-lg-none" data-toggle="collapse"><i className="fas fa-info-circle" aria-hidden="true"></i><span className="sr-only">Description</span></a>
								<h3>{this.state.obDetails.content.cattitle}</h3>
								
								<div id="descr" className="collapse p-3 bg-light d-lg-block" dangerouslySetInnerHTML={createMarkup(this.state.obDetails.content.catdescr)} />
								<hr />
								<ul className="list-group">
									{this.state.obDetails.content.content_ids.map((loc, index) => {
										return <CategoryItem key={loc.content_id} content_id={loc.content_id} loc_id={loc.loc_id} lat={loc.lat} lng={loc.lng} title={loc.title}/>
									})}									

								</ul>
							</div>

							<div className="col-md-8 mapRight">
								<GoogleMap markers={this.state.obDetails.content.content_ids} />
							</div>
						</div>
					 </div>
					 
				)			
			}		 
		}
	}

	function createMarkup(htm) {
		return {__html: htm};
	  }
		 

export default Category;