import React from 'react';
import {config} from './../../../config.js';


class Summary extends React.Component {
	constructor(props, context) {
	  super(props, context);
  	 
	  this.state = {
          locCt: props.locCt,
          orgCt: props.orgCt,
          psnCt: props.psnCt,
         
	  };
	}
  
   
 	
	render() {

        var hasOrgs= this.state.orgCt > 0,
        hasLocs= this.state.locCt > 0,
        hasPpl= this.state.psnCt > 0,
        orgPlural= this.state.orgCt !== 1 ? 's' : '',
        locPlural= this.state.locCt !== 1 ? 's' : '',
        psnPlural= this.state.psnCt === 1 ? 'person' : 'people',
        needsCommas = hasLocs && hasOrgs && hasPpl;

        if ( ! (hasOrgs || hasLocs || hasPpl ) ){
            return <p className="lead">No results were returned</p>

        }



        if ( config.APP === 'map' ) {

            return (
                <p className="lead">                
                    Found 
                    {hasLocs? <span> {this.state.locCt} location{locPlural}{hasOrgs || hasPpl? (needsCommas?', ':' and '):' '  } </span>:' '}
                    {hasOrgs? <span> {this.state.orgCt} organization{orgPlural}{hasPpl? (needsCommas?', ':' and '):' '  } </span>:' '}
                    {hasPpl? <span> {hasLocs || hasOrgs?' and ':''} {this.state.psnCt} {psnPlural}  </span>:' '}
                </p>
            );

        } else {
            return (
                <p className="lead">                
                    Found 
                    {hasOrgs? <span> {this.state.orgCt} organization{orgPlural}{hasLocs || hasPpl? (needsCommas?', ':' and '):' '  } </span>:' '}
                    {hasPpl? <span> {this.state.psnCt} {psnPlural}{hasLocs? (needsCommas?', ':' and '):' '  } </span>:' '}
                    {hasLocs? <span>  {hasPpl || hasOrgs?' and ':''}  {this.state.locCt} location{locPlural} </span>:' '}

                </p>
            );


        }

		
	}
  }

  export default Summary;
